<template>
    <section class='bg-white'>
        <div class="object-cover w-full bg-center bg-cover h-[25rem] "
        style="background-image: url(https://i.postimg.cc/P5gCz1Wc/pexels-marc-mueller-380769.jpg);">
        <div class="flex flex-col items-center justify-center w-full h-full text-white bg-[linear-gradient(rgba(237,172,36,0.4),rgba(0,0,0,0.4))] ">
            <div class="text-center">
                <h1 class="text-4xl font-semibold uppercase lg:text-5xl">Contact us </h1>
            </div>
            <div class="flex gap-2 text-sm lg:text-base">
                <router-link to="/" ><button class="hover:text-[#EDAC24]">Home</button></router-link>
                    /
                <button>Contact us</button>
            </div>
        </div>
    </div>

        <div class='container mx-auto sm:px-10 px-3'>
           
           
           <div class="grid xl:grid-cols-6 gap-0 py-5 xl:gap-10">
            <div class='col-span-2 flex flex-col gap-12 py-5 xl:py-10 xl:pt-20'>
                <div class='flex gap-5 items-start'>
                    <button class='px-5 py-3 rounded-md bg-[#ffefca] text-[#EDAC24]'><i class="fa-solid fa-globe text-3xl"></i></button>
                    <div>
                        <h4 class='font-semibold text-2xl'>Support</h4>
                        <p>Get detailed answers to your queries on our customer support.</p>
                        <a href="#" class='font-medium text-[#EDAC24]'>Support Center</a>
                    </div>
                </div>
                <div class='flex gap-5 items-start'>
                    <button class='px-5 py-3 rounded-md bg-[#ffefca] text-[#EDAC24]'><i
                            class="fa-solid fa-location-dot text-3xl"></i></button>
                    <div>
                        <h4 class='font-semibold text-2xl'>Address:</h4>
                        <p>Anaheim, Calfifornia, United States.</p>
                        <!-- <a href="#" class='font-medium text-[#EDAC24]'>info@selfshiplabel.com</a> -->
                    </div>
                </div>
                <div class='flex gap-5 items-start'>
                    <button class='px-5 py-3 rounded-md bg-[#ffefca] text-[#EDAC24]'><i class="fa-solid fa-envelope text-3xl"></i></button>
                    <div>
                        <h4 class='font-semibold text-2xl'>Email us:</h4>
                        <p> For inquiries, drop us an email at</p>
                        <a href="#" class='font-medium text-[#EDAC24]'>info@selfshiplabel.com</a>
                    </div>
                </div>
                <div class='flex gap-5 items-start'>
                    <button class='px-5 py-3 rounded-md bg-[#ffefca] text-[#EDAC24]'><i class="fa-solid fa-phone text-3xl"></i></button>
                    <div>
                        <h4 class='font-semibold text-2xl'>Call us:</h4>
                        <p>Need immediate assistance? Call us at</p>
                        <a href="#" class='font-medium text-[#EDAC24]'>+1 (657) 210-1801</a>
                    </div>
                </div>
               
                

            </div>
            <div class="col-span-4 xl:py-8 w-full">
                <div class="py-8 xl:py-10 xl:px-3 w-full">

                    <form action="#" class="space-y-8">
                        <div class='grid md:grid-cols-2 items-end gap-5'>
                            <div class=" relative h-fit z-0 w-full group">
                                <input type="text" name="floating_name" id="floating_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#EDAC24] peer" placeholder=" " required />
                                <label for="floating_name" class="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#EDAC24] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First Name</label>
                            </div>
                            <div class=" relative h-fit z-0 w-full group">
                                <input type="text" name="floating_passion" id="floating_passion" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#EDAC24] peer" placeholder=" " required />
                                <label for="floating_passion" class="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#EDAC24] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Last Name</label>
                            </div>
                        </div>
                        <div class=" relative h-fit z-0 w-full group">
                            <input type="email" name="floating_email" id="floating_email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#EDAC24] peer" placeholder=" " required />
                            <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#EDAC24] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
                        </div>
                        <div class=" relative h-fit z-0 w-full group">
                            <input type="text" name="floating_subject" id="floating_subject" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#EDAC24] peer" placeholder=" " required />
                            <label for="floating_subject" class="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#EDAC24] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Subject</label>
                        </div>
                    
                        <div class=" relative h-full z-0 w-full group">
                            <textarea rows="7" type="text" name="floating_message" id="floating_message" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#EDAC24] peer" placeholder=" " required></textarea>
                            <label for="floating_message" class="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#EDAC24] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Message</label>
                        </div>
                        <button type="submit"
                            class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-[#EDAC24] sm:w-fit">Send
                            message</button>
                    </form>
                </div>
            </div>
           </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            
            searchText: '',
        };
    },
    methods: {
        search() {
            // Add your search functionality here
        },
    },
};
</script>