<template>
    <!-- SideBar -->
     <div class="fixed inset-0 bg-[rgba(11,11,11,0.5)] z-[99999] transition-all overflow-hidden">
         <div id="drawer-navigation" class="fixed top-0 right-0 z-40 w-[310px] h-screen overflow-y-auto transition-all  bg-white shadow-xl border-l"
             tabindex="-1" aria-labelledby="drawer-navigation-label">
             <div class="bg-gradient-to-br from-[#FFD200] to-[#CD7D00] p-4 flex items-center justify-between">
                 <h5 id="drawer-navigation-label" class="text-base font-semibold text-white uppercase">Menu</h5>
                 <button type="button" @click="closeSidebar"
                     class="h-7 w-7 text-white bg-transparent hover:bg-white hover:text-[#EDAC24] rounded-md text-lg flex justify-center items-center">
                     <i class="fa-solid fa-xmark text-lg"></i>
                     <span class="sr-only">Close menu</span>
                 </button>
             </div>
             <ul class="lg:hidden p-4 pt-10 flex flex-col gap-2">
                 <li><router-link class="flex items-center p-2 text-gray-900 rounded-lg hover:text-white hover:bg-gradient-to-r from-[#FFD200] to-[#CD7D00] group" :to="{ name: 'home' }" :class="getActiveClass('home')"> <span class="flex-1 ml-3 whitespace-nowrap">Home</span> </router-link> </li>
                 <li><router-link class="flex items-center p-2 text-gray-900 rounded-lg hover:text-white hover:bg-gradient-to-r from-[#FFD200] to-[#CD7D00] group" :to="{ name: 'shipment' }" :class="getActiveClass('shipment')"> <span class="flex-1 ml-3 whitespace-nowrap">Ship Now</span> </router-link> </li>
                 <li><router-link class="flex items-center p-2 text-gray-900 rounded-lg hover:text-white hover:bg-gradient-to-r from-[#FFD200] to-[#CD7D00] group" :to="{ name: 'calculator' }" :class="getActiveClass('calculator')"> <span class="flex-1 ml-3 whitespace-nowrap">Shipping Rates</span> </router-link> </li>
                 <li><router-link class="flex items-center p-2 text-gray-900 rounded-lg hover:text-white hover:bg-gradient-to-r from-[#FFD200] to-[#CD7D00] group" :to="{ name: 'about' }" :class="getActiveClass('about')"> <span class="flex-1 ml-3 whitespace-nowrap">About</span> </router-link> </li>
                 <li><router-link class="flex items-center p-2 text-gray-900 rounded-lg hover:text-white hover:bg-gradient-to-r from-[#FFD200] to-[#CD7D00] group" :to="{ name: 'contact-us' }" :class="getActiveClass('contact-us')"> <span class="flex-1 ml-3 whitespace-nowrap">Contact</span> </router-link> </li>
            </ul>
             <div class="p-4 overflow-y-auto">
                 <ul class="space-y-2 font-medium">
                     <template v-if="token">
                         <li>
                             <router-link :to="{ name: 'account' }" :class="getActiveClass('account')"
                                 class="flex items-center p-2 text-gray-900 hover:text-white rounded-lg hover:bg-gradient-to-r from-[#FFD200] to-[#CD7D00] group">
                                 <i class="fas fa-chart-line"></i>
                                 <span class="ml-3">Dashboard</span>
                             </router-link>
                         </li>
                         <!-- <li>
                             <router-link to="/account"
                                 class="flex items-center p-2 text-gray-900 rounded-lg hover:text-white hover:bg-[#EDAC24] group">
                                 <i class="fas fa-box"></i>
                                 <span class="flex-1 ml-3 whitespace-nowrap">Packages</span>
                             </router-link>
                         </li> -->
                         <li>
                             <a href="#" @click="logout"
                                 class="flex items-center p-2 text-gray-900 rounded-lg hover:text-white hover:bg-gradient-to-r from-[#FFD200] to-[#CD7D00] group">
                                 <i class="fas fa-sign-in-alt"></i>
                                 <span class="flex-1 ml-3 whitespace-nowrap">Logout</span>
                             </a>
                         </li>
                     </template>
     
                     <template v-else>
                         <li>
                             <router-link to="/login"
                                 class="flex items-center p-2 text-gray-900 rounded-lg hover:text-white hover:bg-gradient-to-r from-[#FFD200] to-[#CD7D00] group">
                                 <i class="fas fa-sign-in-alt"></i>
                                 <span class="flex-1 ml-3 whitespace-nowrap">Sign In</span>
                             </router-link>
                         </li>
                         <li>
                             <router-link to="/signup"
                                 class="flex items-center p-2 text-gray-900 rounded-lg hover:text-white hover:bg-gradient-to-r from-[#FFD200] to-[#CD7D00] group">
                                 <i class="fas fa-user-plus"></i>
                                 <span class="flex-1 ml-3 whitespace-nowrap">Sign Up</span>
                             </router-link>
                         </li>
                     </template>
                 </ul>
             </div>
         </div>
     </div>
</template>

<style>
    .sidebar_item_active{
        color: white !important;
        background: linear-gradient(to right bottom, #FFD200,#CD7D00);
    }
</style>

<script>
export default {
    name: "SidebarComponent",
    computed: {
        currentRoute() {
            return this.$route.name; 
        }
    },
    data() {
        return {
            token: null
        };
    },
    methods: {
        getActiveClass(routeName) {
            return this.currentRoute === routeName ? 'sidebar_item_active' : '';
        },
        closeSidebar() {
            this.$emit('close-sidebar');
        },
        logout() {
            localStorage.removeItem("token");
            localStorage.removeItem("username");
            this.$router.push("/login");
        },
    },
    mounted() {
        this.token = localStorage.getItem('token');
    }
};
</script>