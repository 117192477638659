<template>
	<section class="section_login grid grid-cols-1 xl:grid-cols-2 xl:items-stretch items-center min-h-[100vh] h-full"
		style="background-color: rgb(237, 172, 36)">
		<div class="section_login-form text-white flex flex-col justify-center h-full">
			<div class="section_login-form_logo flex flex-col justify-center items-center pt-8">
				<router-link to="/">
					<h2 class="md:text-5xl text-4xl font-bold"><span class="text-black">Self Ship </span>Label</h2>
				</router-link>
			</div>
			<div class="section_login-form_content flex flex-col justify-center py-14 px-7 sm:px-16 lg:px-24 xl:px-32 h-full">
				<h1 class="text-[32px] font-[600] text-left">Sign Up</h1>

				<form class="section_login-form_content-input flex flex-col space-y-4 pt-2"
					@submit.prevent="handleSubmit()">
					<div class="flex flex-col gap-2">
						<label for="name" class="text-black">Name</label>
						<input v-model="form.name" type="text" placeholder="Enter your name"
							class="py-3 rounded-lg bg-transparent outline-none border-2 border-white p-2 placeholder:text-white  focus:border-black focus:ring-0" />
						<span class="text-red-600 font-medium">{{ errors?.errors?.name }}</span>
					</div>
					<div class="flex flex-col gap-2">
						<label for="email" class="text-black">Email</label>
						<input v-model="form.email" type="email" placeholder="Enter your email"
							class="py-3 rounded-lg bg-transparent outline-none border-2 border-white p-2 placeholder:text-white  focus:border-black focus:ring-0" />
						<span class="text-red-600 font-medium">{{ errors?.errors?.email }}</span>
					</div>
					<div class="flex flex-col gap-2">
						<label for="password" class="text-black">Password</label>
						<div class="relative">
							<input v-model="form.password" id="password" :type="showPassword ? 'text' : 'password'"
								class="pass1 py-3 w-full border-2 border-white rounded-lg outline-none p-2 bg-transparent placeholder:text-white  focus:border-black focus:ring-0"
								placeholder="Enter your password" />
							<img @click="toggleShowPassword" class="eye_1 absolute top-3 right-3 cursor-pointer"
								src="../assets/icons/icon-eye-open.svg" alt="pass-eye" />
						</div>
						<span class="text-red-600 font-medium">{{ errors?.errors?.password }}</span>
					</div>
					<div class="flex flex-col gap-2">
						<label for="password" class="text-black">Retype Password</label>
						<div class="relative">
							<input v-model="form.confirm_password" id="password" :type="showPassword ? 'text' : 'password'"
								class="pass1 py-3 w-full border-2 border-white rounded-lg outline-none p-2 bg-transparent placeholder:text-white focus:border-black focus:ring-0"
								placeholder="Enter your password again" />
							<img @click="toggleShowPassword" class="eye_1 absolute top-3 right-3 cursor-pointer"
								src="../assets/icons/icon-eye-open.svg" alt="pass-eye" />
						</div>
						<span class="text-red-600 font-medium">{{ errors?.errors?.confirm_password }}</span>
					</div>
					<div class="flex justify-between items-center">
						<div class="flex justify-center items-center space-x-2 pl-1">
							<input id="stay-sign-in" type="checkbox"
								class="stay-sign-in rounded bg-transparent border-0 focus:ring-0" style="
									outline: 2px solid white;
									width: 16px;
									height: 16px;
									border: none;
								" />
							<label for="stay-sign-in" class="font-[400]">Stay signed-in</label>
						</div>
						<a href="#" class="font-[600] text-sm">Forget password?</a>
					</div>
					<button type="submit" :class="loading ? 'text-[#4a4b4d88] ' : 'text-black '"
						class="bg-white text-[18px] font-medium borde w-full py-[10px] rounded-lg hover:bg-slate-200 transition">
						{{ loading ? 'Loading ...' : 'Sign Up' }}
					</button>
				</form>
			</div>
		</div>
		<div class="section_login-content justify-center items-center xl:flex hidden bg-white h-full">
			<img src="../assets/images/home-page-cover.png" alt="section_login-content_img"
				class="h-[424.08px] w-[570px]" />
		</div>
	</section>
</template>

<script>
export default {
	name: "SignupComponent",
	data() {
		return {
			form: {
				name: "",
				email: "",
				password: "",
				confirm_password: "",
			},
			errors: [],
			loading: false,
			showPassword: false,
		};
	},
	methods: {
		toggleShowPassword() {
			this.showPassword = !this.showPassword;
		},
		handleSubmit() {
			this.loading = true;
			this.errors = [];

			this.$axios
				.post("register", this.form)
				.then((response) => {
					console.log(response);
					this.$router.push("login");
				})
				.catch((error) => {
					this.errors = error.response.data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style>
#stay-sign-in {
	border: none !important;
}
</style>
