<template>
	<template v-if="this.$route.name == 'login' || this.$route.name == 'signup'">
		<router-view />
	</template>
	<template v-else>
		<NavbarComponent></NavbarComponent>
		<div class="pt-[75px] sm:pt-[85px]">
			<router-view />
		</div>
		<FooterComponent></FooterComponent>
	</template>
</template>

<script>
import NavbarComponent from "./components/NavbarComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";

export default {
	name: "App",
	components: { NavbarComponent, FooterComponent },
};
</script>
