<template>
	<nav class="flex items-center justify-between pt-4" aria-label="Table navigation">
		<span class="text-sm font-normal text-gray-500 dark:text-gray-400">Showing <span
				class="font-semibold text-gray-900 dark:text-white">{{ pagination.from }}-{{ pagination.to }}</span> of <span
				class="font-semibold text-gray-900 dark:text-white">{{ pagination.total }}</span></span>

		<paginate :page-count="pagination.last_page" :click-handler="clickCallback" prev-text="Previous" next-text="Next"
			:prev-class="'flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'"
			:next-class="'flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'"
			:container-class="'pagination inline-flex -space-x-px text-sm h-8'"
			:page-class="'cursor-pointer flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'">
		</paginate>
	</nav>
</template>

<script>
import Paginate from "vuejs-paginate-next";

export default {
	name: "TableFooter",
	components: {
		Paginate,
	},
	props: {
		pagination: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			page: 10,
		};
	},
	methods: {
		clickCallback(pageNum) {
			console.log(pageNum)
			this.page_no = pageNum;
			this.$emit("fetchRecords");
		},
	},
};
</script>

<style>
.pegination {
	user-select: none;
}

.pagination li.active {
	background-color: beige;
}

.pegination .page-item .page-link {
	border-radius: 0.5rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity));
	color: black;
}

.pegination .page-item:first-child .page-link,
.pegination .page-item:last-child .page-link {
	background: transparent !important;
	position: relative;
}

/* .pegination .page-item:first-child .page-link:before {
		content: "\2039";
		font-size: 50px;
		position: absolute;
		top: 3px;
	} */
</style>
