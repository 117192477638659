<template>
    <section class="py-5 sm:px-10 px-3 min-h-[80vh]">
        <h3 class="text-3xl font-semibold">My account</h3>
        <ul class="flex items-center gap-3 text-lg py-4 lg:flex-nowrap flex-wrap">
            <template v-for="tab in tabs" :key="tab.id">
                <li class="font-medium hover:text-[#EDAC24]" :class="{ 'text-[#EDAC24]': active_tab === tab.value }"
                    @click="active_tab = tab.value"><a href="#">{{ tab.text }}</a></li>
            </template>
        </ul>

        <AccountDashboard v-if="active_tab == 'dashboard'"></AccountDashboard>
        <AccountOrder v-if="active_tab == 'orders'"></AccountOrder>
        <AccountInquiries v-if="active_tab == 'inquiries'"></AccountInquiries>
        <AccountProfile v-if="active_tab == 'profile'"></AccountProfile>
        <AccountAddress v-if="active_tab == 'addresses'"></AccountAddress>
        <AccountSecurity v-if="active_tab == 'security'"></AccountSecurity>

    </section>
</template>


<script>
import AccountAddress from './AccountAddress.vue';
import AccountOrder from './AccountOrder.vue';
import AccountProfile from './AccountProfile.vue';
import AccountDashboard from './AccountDashboard.vue';
import AccountSecurity from './AccountSecurity.vue';
import AccountInquiries from './AccountInquiries.vue';


export default {
    name: "AccountComponent",
    data() {
        return {
            active_tab: "dashboard",
            tabs: [
                { text: "Dashboard", value: "dashboard" },
                { text: "Orders", value: "orders" },
                { text: "Inquiries", value: "inquiries" },
                { text: "Addresses", value: "addresses" },
                // { text: "Profile", value: "profile" },
                // { text: "Security", value: "security" },
            ],
        };
    },
    mounted() {
        const queryString = window.location.search;
        if (queryString) {
            const params = new URLSearchParams(queryString.substring(1));
            this.inquiryParam = params.get('inquiry');
            if (this.inquiryParam == 1) {
                this.active_tab = "inquiries";
            } else {
                this.active_tab = "dashboard";

            }
        }
    },
    components: { AccountProfile, AccountOrder, AccountAddress, AccountDashboard, AccountSecurity, AccountInquiries }
};
</script>