<template>

    <div class="object-cover w-full bg-center bg-cover h-[25rem] "
        style="background-image: url(https://i.postimg.cc/P5gCz1Wc/pexels-marc-mueller-380769.jpg);">
        <div class="flex flex-col items-center justify-center w-full h-full text-white bg-[linear-gradient(rgba(237,172,36,0.4),rgba(0,0,0,0.4))] ">
            <div class="text-center">
                <h1 class="text-4xl font-semibold uppercase lg:text-5xl">About us </h1>
            </div>
            <div class="flex gap-2 text-sm lg:text-base">
                <router-link to="/" ><button class="hover:text-[#EDAC24]">Home</button></router-link>
                    /
                <button>About us</button>
            </div>
        </div>
    </div>
    
     <section class="container mx-auto overflow-hidden bg-white py-8 sm:py-16 sm:px-20 px-3">
        <div class="mx-auto">
            <div class=" mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 2xl:mx-0 2xl:max-w-none 2xl:grid-cols-2">
                <div class="2xl:pr-8 2xl:pt-4">
                    <div class="2xl:max-w-lg">
                        <h2 class="text-base font-semibold leading-7 text-[#EDAC24]">Deliver faster</h2>
                        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Why Self Ship Label ?</p>
                        <p class="mt-6 text-base leading-8 text-gray-600">Sending packages worldwide has never been easier! With Self Ship Label, you're in control.</p>
                        <dl class="mt-5 max-w-xl space-y-5 text-base leading-7 text-gray-600 lg:max-w-none">
                            <div class="relative pl-8">
                                <dt class="inline font-semibold text-gray-900"><svg xmlns="http://www.w3.org/2000/svg" class="absolute left-1 top-1 h-5 w-5 text-[#EDAC24]" viewBox="0 0 640 512" fill="currentColor"><path d="M112 0C85.5 0 64 21.5 64 48V96H16c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 272c8.8 0 16 7.2 16 16s-7.2 16-16 16H64 48c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 240c8.8 0 16 7.2 16 16s-7.2 16-16 16H64 16c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 208c8.8 0 16 7.2 16 16s-7.2 16-16 16H64V416c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H112zM544 237.3V256H416V160h50.7L544 237.3zM160 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm272 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z"/></svg>
                                    Deliver faster
                                </dt>
                                <dd class="inline">No more waiting – get your products quickly and efficiently with Self Ship Label.
                                </dd>
                            </div>
                            <div class="relative pl-8">
                                <dt class="inline font-semibold text-gray-900">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="absolute left-1 top-1 h-5 w-5 text-[#EDAC24]" fill="currentColor" viewBox="0 0 448 512"><path d="M448 156.1c0-4.5-.1-9-.2-13.5a196.3 196.3 0 0 0 -2.6-29.4 99.6 99.6 0 0 0 -9.2-28A94.1 94.1 0 0 0 394.8 44a99.2 99.2 0 0 0 -28-9.2 195 195 0 0 0 -29.4-2.6c-4.5-.1-9-.2-13.5-.2H124.1c-4.5 0-9 .1-13.5 .2-2.5 .1-4.9 .2-7.4 .3a171.7 171.7 0 0 0 -22.1 2.3 103.1 103.1 0 0 0 -21.2 6.1q-3.5 1.5-6.8 3.1a94.7 94.7 0 0 0 -18.4 12.3c-1.9 1.6-3.7 3.3-5.4 5A93.9 93.9 0 0 0 12 85.2a99.5 99.5 0 0 0 -9.2 28 196.3 196.3 0 0 0 -2.5 29.4c-.1 4.5-.2 9-.2 13.5v199.8c0 4.5 .1 9 .2 13.5a196.1 196.1 0 0 0 2.6 29.4 99.3 99.3 0 0 0 9.2 28A94.3 94.3 0 0 0 53.2 468a99.5 99.5 0 0 0 28 9.2 195 195 0 0 0 29.4 2.6c4.5 .1 9 .2 13.5 .2H323.9c4.5 0 9-.1 13.5-.2a196.6 196.6 0 0 0 29.4-2.6 99.6 99.6 0 0 0 28-9.2A94.2 94.2 0 0 0 436 426.8a99.3 99.3 0 0 0 9.2-28 194.8 194.8 0 0 0 2.6-29.4c.1-4.5 .2-9 .2-13.5V172.1c0-5.4 0-10.7 0-16.1zm-69.9 241c-20-38.9-57.2-29.3-76.3-19.5-1.7 1-3.5 2-5.3 3l-.4 .3c-39.5 21-92.5 22.5-145.9-.4A234.6 234.6 0 0 1 45 290.1a230.6 230.6 0 0 0 39.2 23.4c56.4 26.4 113 24.5 153 0-57-43.9-104.6-101-141.1-147.2a197.1 197.1 0 0 1 -18.8-25.9c43.7 40 112.7 90.2 137.5 104.1-52.6-55.5-98.9-123.9-96.7-121.7 82.8 83.4 159.2 130.6 159.2 130.6 2.9 1.6 5 2.9 6.7 4a127.4 127.4 0 0 0 4.2-12.5c13.2-48.3-1.7-103.6-35.3-149.2C329.6 141.8 375 229.3 356.4 303.4c-.4 1.7-1 3.4-1.4 5.1 38.5 47.4 28 98.2 23.1 88.6z"/></svg>
                                    Swift Delivery
                                </dt>
                                <dd class="inline">We prioritise swift delivery without compromising the safety and security of your packages.</dd>
                            </div>
                            <div class="relative pl-8">
                                <dt class="inline font-semibold text-gray-900">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="absolute left-1 top-1 h-5 w-5 text-[#EDAC24]" fill="currentColor" viewBox="0 0 576 512"><path d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zm64 320H64V320c35.3 0 64 28.7 64 64zM64 192V128h64c0 35.3-28.7 64-64 64zM448 384c0-35.3 28.7-64 64-64v64H448zm64-192c-35.3 0-64-28.7-64-64h64v64zM288 160a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg>
                                    Cheap Service </dt>
                                <dd class="inline">Our platform ensures you save up to 80% on your shipping costs.
                                </dd>
                            </div>
                            <div class="relative pl-8">
                                <dt class="inline font-semibold text-gray-900">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="absolute left-1 top-1 h-5 w-5 text-[#EDAC24]" fill="currentColor" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd" d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.004-.001.274-.11a.75.75 0 0 1 .558 0l.274.11.004.001zm-1.374.527L8 5.962 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339Z"/>
                                    </svg>
                                    Personal Packages </dt>
                                <dd class="inline">From business shipments to personal packages, we've got you covered.
                                </dd>
                            </div>
                        </dl>
                    </div>
                  
                </div>
                <div class="w-full px-4 mb-10 lg:mb-0">
                    <div class="relative w-full">
                        <img src="../assets/images/about.png" alt="Product screenshot"
                        class="relative z-10 object-cover w-[45rem] sm:h-[35rem] sm:w-[40rem] h-[25rem] rounded-xl shadow-xl ring-1 ring-gray-400/10">
                        
                        <!-- <div
                            class="absolute bottom-2 right-2 z-10 px-4 py-4 rounded-xl bg-[rgba(255,255,255,0.8)] shadow ">
                            <p class="text-sm font-semibold">
                                Providing business solutions from 10 years
                            </p>
                        </div> -->

                        <div class="absolute hidden sm:w-[40rem] h-full bg-[#FFD16E] rounded-xl -bottom-4 left-4 lg:block">
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </section>

    <section class="bg-white py-20 pt-3 sm:py-28 sm:pt-5 sm:px-20 px-3">
      <div class="mx-auto container">
        <div class="mx-auto max-w-2xl lg:text-center">
          <h2 class="text-base font-semibold leading-7 text-[#EDAC24]">Deliver faster</h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">What Self Ship Label Do ?</p>
          <p class="mt-4 text-lg leading-8 text-gray-600">Self Ship Label - Your packages, your way.</p>
        </div>
        <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            <div v-for="feature in features" :key="feature.name" class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#EDAC24]">
                  <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                {{ feature.name }}
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">{{ feature.description }}</dd>
            </div>
          </dl>
        </div>
      </div>
    </section>

    <section class="relative py-24 sm:py-32 sm:px-20 px-3 bg-[#F8EACA]">
        <div class="relative z-10 max-w-screen-xl mx-auto justify-between gap-24 lg:flex">
        <div class="">
            <h3 class=" text-3xl font-semibold sm:text-4xl">
                Ship Smarter With Self Ship Label
            </h3>
            <p class="mt-3 text-gray-600">
                Get Fast, Affordable, Reliable Shipping with Self Ship Label. Sending packages worldwide has never been easier! With Self Ship Label, you're in control.
            </p>
                <div class="flex gap-3 items-center py-4">
                    <a href="javascript:void(0)" class="inline-block py-2 px-4 text-white font-medium bg-[#EDAC24] duration-150  rounded-lg shadow-md hover:shadow-none">Get started </a>
                    <!-- <a href="javascript:void(0)" class="inline-block py-2 px-4 text-white font-medium bg-[#EDAC24] duration-150  rounded-lg shadow-md hover:shadow-none">Get started </a> -->
                </div>
        </div>
        <div class="mt-12 max-w-xl lg:mt-0">
            <ul class="grid gap-8 sm:grid-cols-1">
            <li v-for="(item, idx) in aims" :key="idx" class="flex gap-x-4">
                <div class="flex-none w-12 h-12 bg-[#EDAC24] text-white rounded-lg flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" :d="item.icon" />
                </svg>
                </div>
                <div>
                <h4 class="text-lg font-semibold">
                    {{ item.title }}
                </h4>
                <p class="mt-3 text-gray-600">
                    {{ item.desc }}
                </p>
                
                </div>
            </li>
            </ul>
        </div>
        </div>
        <div class="absolute top-1/3 inset-0 max-w-md mx-auto h-72 blur-[118px]" :style="{ background: 'linear-gradient(152.92deg, rgba(237, 172, 36, 0.3) 4.54%, rgba(237, 172, 36, 0.26) 34.2%, rgba(237, 172, 36, 0.3) 77.55%)' }"></div>
    </section>

    <section class="bg-white py-24 sm:py-32 sm:px-20 px-3">
        <div class="mx-auto container md:text-center">
            <div class="max-w-2xl space-y-3 md:mx-auto">
                <h3 class="text-[#EDAC24] font-semibold">
                    Professional services
                </h3>
                <p class="text-gray-800 text-3xl font-semibold sm:text-4xl">
                Self Ship Label, Your Global Control Center
                </p>
                <p class="text-gray-600">
                    Experience efficiency, control, and ease of use with the recently introduced Self Ship Label by Self Ship Label. Whether you're a company going global, a frequent online shopper, or a person extending love across borders, our platform is made to assist you. Greetings from a new, empowered era of international shipping.
                </p>
            </div>
            <div class="mt-4">
                <a href="javascript:void(0)" class="inline-block py-2 px-4 text-white font-medium bg-[#EDAC24] duration-150  rounded-lg shadow-md hover:shadow-none">
                    Get started
                </a>
            </div>
        </div>
    </section>

</template>

   
  
  <script setup>
  
    const features = [
        {
            name: 'Save You Time and Money',
            description: 'Our Self Ship Label platform is tailored to save you valuable time and money on shipping packages. Enjoy efficiency at every step of the process.',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-alarm" viewBox="0 0 16 16">
                        <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9z"/>
                        <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1zm1.038 3.018a6 6 0 0 1 .924 0 6 6 0 1 1-.924 0M0 3.5c0 .753.333 1.429.86 1.887A8.04 8.04 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5M13.5 1c-.753 0-1.429.333-1.887.86a8.04 8.04 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1"/>
                    </svg>,
        },
        {
            name: 'Find You Discounted Rates',
            description: 'Benefit from our partnerships with premium couriers to find discounted rates. Self Ship Label ensures you get the best deals without compromising on service quality.',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
                        <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0"/>
                        <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1m0 5.586 7 7L13.586 9l-7-7H2z"/>
                    </svg>,
        },
        {
            name: 'Help You Compare Prices and Services',
            description: 'Easily compare prices and services to over 200 global destinations. Make informed decisions with Self Ship Label.',
            icon: <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M544 248v3.3l69.7-69.7c21.9-21.9 21.9-57.3 0-79.2L535.6 24.4c-21.9-21.9-57.3-21.9-79.2 0L416.3 64.5c-2.7-.3-5.5-.5-8.3-.5H296c-37.1 0-67.6 28-71.6 64H224V248c0 22.1 17.9 40 40 40s40-17.9 40-40V176c0 0 0-.1 0-.1V160l16 0 136 0c0 0 0 0 .1 0H464c44.2 0 80 35.8 80 80v8zM336 192v56c0 39.8-32.2 72-72 72s-72-32.2-72-72V129.4c-35.9 6.2-65.8 32.3-76 68.2L99.5 255.2 26.3 328.4c-21.9 21.9-21.9 57.3 0 79.2l78.1 78.1c21.9 21.9 57.3 21.9 79.2 0l37.7-37.7c.9 0 1.8 .1 2.7 .1H384c26.5 0 48-21.5 48-48c0-5.6-1-11-2.7-16H432c26.5 0 48-21.5 48-48c0-12.8-5-24.4-13.2-33c25.7-5 45.1-27.6 45.2-54.8v-.4c-.1-30.8-25.1-55.8-56-55.8c0 0 0 0 0 0l-120 0z"/></svg>,
        },
        {
            name: 'Sell Prepaid Shipping Labels',
            description: 'Simplify the shipping process with prepaid shipping labels available on Self Ship Label. Just attach them to your packages and let us handle the rest.',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
                </svg>,
        },
       
    ]

    const aims = [
        {
          icon: 'M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z',
          title: 'International Shipping Simplified',
          desc: 'Getting around the hurdles of international shipping has never been easier. Self Ship Label streamlines the entire process, from packaging to customs clearance, documentation, and final delivery. We bring you a comprehensive solution that ensures your items reach their destination smoothly.'
        },
        {
          icon: 'M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z',
          title: 'The Aim of Self Ship Label',
          desc: 'At Self Ship Label we aim to simplify international shipping, making it accessible and hassle-free for everyone. Our aim is of a world where economical and effective shipping technologies allow boundaries to be seamlessly integrated. Self Ship Label aspires to be your go-to partner for all international shipping needs by demonstrating a dedication to innovation and client satisfaction.'
        },
      
      ]
  </script>