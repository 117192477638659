<template>
    <div class="bg-white shadow-md rounded-xl p-5 h-full">
        <template v-if="pkg.signature_type_id">
            <b>Signature</b>
            <button type="button" class=" float-right font-medium text-blue-600 dark:text-blue-500 hover:underline px-2"
                @click="openSignatureModal()">Edit</button>
            <p class="pt-2"><b>Type:</b> {{ signatureTypeName }}</p>
        </template>

        <template v-else>
            <div class="flex justify-center h-full w-full items-center">
                <div class="flex flex-col items-center justify-center gap-2">
                    <button @click="openSignatureModal()"
                        class="hover:bg-black hover:text-white hover:border-white hover:border-2 h-[30px] md:h-[40px] w-[30px] md:w-[40px] text-black border-2 border-black font-semibold flex justify-center items-center rounded-full text-xl"
                        type="button"><span>+</span></button>
                    <p>Add Signature</p>
                </div>
            </div>
        </template>
    </div>

    <template v-if="signature_form.modal">
        <div id="defaultModal" tabindex="-1" aria-hidden="true"
            class="z-[1000] fixed bg-[rgba(11,11,11,0.5)] m-[0!important] top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-[100vh] h-[100vh] ">
            <div class="relative m-auto w-full max-w-5xl h-full max-h-[100vh]">
                <!-- Modal content -->
                <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white w-full rounded-lg shadow">
                    <!-- Modal header -->
                    <div class="flex items-start justify-between px-4 pt-4 pb-2 border-b rounded-t">
                        <h3 class="mb-2 text-2xl leading-none font-bold text-gray-900">
                            Signature
                        </h3>
                        <button type="button" @click="closeCustomsModal"
                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="defaultModal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>

                    <!-- Body -->
                    <div class="py-3 px-2 sm:px-5 max-h-[450px] md:max-h-[520px] overflow-y-auto overflow-x-hidden">
                        <!-- <DangerAlert :message="errors.message"></DangerAlert> -->

                        <form @submit.prevent="submitSignatureForm" class="space-y-3">
                            <div class="flex flex-col gap-4 h-fit pt-2 py-2">
                                <!-- MODAL BODY CONTENT START -->
                                <div class="grid gap-4 mb-0 sm:grid-cols-2 grid-cols-1">
                                    <div class="sm:w-auto w-full sm:col-span-1 col-span-2">
                                        <label for="export" class="block mb-2 text-sm font-medium text-gray-900">Signature
                                            Options</label>
                                        <select type="text" v-model="signature_form.signature_type_id"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500"
                                            required>
                                            <template v-for="signature_type in signature_types" :key="signature_type.id">
                                                <option :value="signature_type.id">{{ signature_type.name }}</option>
                                            </template>
                                        </select>
                                        <!-- <span class="text-red-600">{{ errors?.errors?.signature_type_id }}</span> -->
                                    </div>

                                </div>

                                <div
                                    class="py-2 px-2 mb-2 text-sm text-black border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800">
                                    <div class="">
                                            <!-- No Signature Required -->
                                            <div class="p-2 rounded-lg shadow-sm">
                                                <h3 class="font-semibold text-gray-700">No Signature Required</h3>
                                                <p class="text-gray-600 mt-2">Ideal for those who prefer flexibility in
                                                    delivery. Your package will be left at the delivery location without
                                                    requiring a signature.</p>
                                            </div>

                                            <!-- Indirect Signature -->
                                            <div class="p-2 rounded-lg shadow-sm">
                                                <h3 class="font-semibold text-gray-700">Indirect Signature</h3>
                                                <p class="text-gray-600 mt-2">A signature is required from someone at the
                                                    delivery address, a neighbor, or a building manager. Perfect for added
                                                    security when you may not be home.</p>
                                            </div>

                                            <!-- Direct Signature -->
                                            <div class="p-2 rounded-lg shadow-sm">
                                                <h3 class="font-semibold text-gray-700">Direct Signature</h3>
                                                <p class="text-gray-600 mt-2">Ensures that the package is signed for by
                                                    someone at the delivery address, providing peace of mind that it reaches
                                                    the right hands.</p>
                                            </div>

                                            <!-- Adult Signature Required -->
                                            <div class="p-2 rounded-lg shadow-sm">
                                                <h3 class="font-semibold text-gray-700">Adult Signature Required
                                                </h3>
                                                <p class="text-gray-600 mt-2">For the utmost security, this option requires
                                                    a signature from an adult (21 years or older) at the delivery address.
                                                </p>
                                            </div>
                                        </div>
                                </div>
                                <!-- MODAL BODY CONTENT END -->
                            </div>
                            <div class="flex justify-end">
                                <button type="submit"
                                    class="text-white bg-[#EDAC24] hover:bg-[#f7a900] focus:ring-4 focus:outline-nonefont-medium rounded-lg text-sm px-5 py-3 text-center">
                                    {{ loading ? "Loading ..." : "Save & Update" }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<script>
export default {
    name: "SignatureComponent",
    props: {
        pkg: {
            type: Object
        }
    },
    data() {
        return {
            loading: false,
            signature_form: {
                modal: false,
                package_id: "",
                signature_type_id: "",
                errors: {},
            },
            signature_types: [],
            shipment: {},
        };
    },
    methods: {
        submitSignatureForm() {
            this.loading = true;
            this.errors = {};

            this.signature_form.package_id = this.pkg?.id;

            this.$axios
                .post("package/update-signature", this.signature_form, {
                    headers: this.$headers
                })
                .then((response) => {
                    this.signature_form.signature_type_id = response.data.data.signature_type_id
                    this.loading = false;
                    this.signature_form.modal = false;
                    this.$emit("checkPackagePaymentValidation");
                    this.$emit("fetchPackage");
                })
                .catch(() => {
                    // this.errors = error.response.data;
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openSignatureModal() {
            this.signature_form.modal = true;
            this.signature_form.signature_type_id = this.pkg.signature_type_id
            this.fetchSignatureTypes();
        },
        closeCustomsModal() {
            this.signature_form.modal = false;
        },
        fetchSignatureTypes() {
            this.loading = true;
            const url = "data"
            this.$axios
                .post(url, null, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.signature_types = response.data.data.signature_types;
                    this.loading = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.loading = false;
                });
        }
    },
    mounted() {
        this.fetchSignatureTypes();
    },
    components: {},
    computed: {
        signatureTypeId() {
            return this.pkg.signature_type_id;
        },
        signatureTypeName() {
            const type = this.signature_types.find(
                (type) => type.id === this.signatureTypeId
            );
            return type ? type.name : null;
        },
    }
};
</script>