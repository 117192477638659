<template>

    <nav class="bg-gray-50 dark:bg-gray-700">
        <div class="max-w-screen-xl px-4 py-3 mx-auto">
            <div class="flex items-center">
                <ul class="flex flex-row font-medium mt-0 space-x-8 rtl:space-x-reverse text-sm">
                    <li>
                        <router-link :to="{ name: 'shipment' }" class="text-gray-900 dark:text-white"
                            :class="getActiveClass('shipment')">Create Shipment</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'partial' }" class="text-gray-900 dark:text-white"
                            :class="getActiveClass('partial')">Partial Package</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'draft' }" class="text-gray-900 dark:text-white"
                            :class="getActiveClass('draft')">Draft Package</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'history' }" class="text-gray-900 dark:text-white"
                            :class="getActiveClass('history')">Order History</router-link>
                    </li>
      
                    <li>
                        <router-link :to="{ name: 'cart' }" class="text-gray-900 dark:text-white"
                            :class="getActiveClass('cart')">Ready for Payment 
                            <!-- <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">3</span> -->
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>


</template>

<style>
.active {
    font-weight: bold;
    /* You can customize this as per your design */
    border-bottom: 3px solid #3490dc;
    /* Example active state style */
}
</style>

<script>
export default {
    name: "ShipmentHeader",
    computed: {
        currentRoute() {
            return this.$route.name;
        }
    },
    data() {
        return {
            sidebar: false,
            token: null,
            name: null,
        };
    },
    methods: {
        closeSidebar() {
            this.sidebar = false;
        },
        getActiveClass(routeName) {
            return this.currentRoute === routeName ? 'active' : '';
        }
    },
    mounted() {
        this.token = localStorage.getItem('token');
        this.name = localStorage.getItem('name');
    }
};
</script>