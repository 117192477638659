<template>
   <section class="min-h-[80vh]">
        <div class="object-cover w-full bg-center bg-cover h-[25rem] "
         style="background-image: url(https://i.postimg.cc/P5gCz1Wc/pexels-marc-mueller-380769.jpg);">
            <div class="flex flex-col gap-2 items-center justify-center w-full h-full text-white bg-[linear-gradient(rgba(237,172,36,0.4),rgba(0,0,0,0.4))] ">
                <div class="text-center">
                    <h1 class="text-4xl font-semibold uppercase lg:text-5xl">Shipping Calculator</h1>
                </div>
                <div class="flex gap-2 text-sm lg:text-base">
                    <router-link to="/" ><button class="hover:text-[#EDAC24]">Home</button></router-link>
                        /
                    <button>Shipping Calculator</button>
                </div>
            </div>
        </div>
        <div class="container mx-auto sm:px-10 px-3 py-10">
            <div>
                <h4 class="font-semibold text-2xl pb-2">Shipping Calculator</h4>
                <p>To get shipping rates for any destination just use the shipping calculator calculator below. We offer discounted rates on all shipping methods. These rates are tens of percents better than official rates of carriers.</p>
            </div>

            <!-- Put the iframe inside this div  -->
            <div class="py-10">
                <div class="border border-red-200">Waqas</div>
            </div>
            
        </div>
   </section>
</template>