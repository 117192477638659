<template>
    <section class=' bg-white'>
        <div class="object-cover w-full bg-center bg-cover h-[25rem] "
         style="background-image: url(https://i.postimg.cc/P5gCz1Wc/pexels-marc-mueller-380769.jpg);">
            <div class="flex flex-col items-center justify-center w-full h-full text-white bg-[linear-gradient(rgba(237,172,36,0.4),rgba(0,0,0,0.4))] ">
                <div class="text-center">
                    <h1 class="text-4xl font-semibold uppercase lg:text-5xl">FAQ's</h1>
                </div>
                <div class="flex gap-2 text-sm lg:text-base">
                    <router-link to="/" ><button class="hover:text-[#EDAC24]">Home</button></router-link>
                        /
                    <button>Faq's</button>
                </div>
            </div>
        </div>
        <div class='container mx-auto sm:px-10 px-3'>
            <!-- <div class="pb-10">
                <h2 class="text-base font-semibold leading-7 text-[#EDAC24]">Produce faster</h2>
                <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Here Contact with us.</p>
                <p class="mt-4 text-lg leading-8 text-gray-600">
                    Sending packages worldwide has never been easier! With Self Ship Label, you're in control. So If you have any query then you can contact with us.
                </p>
                <button
                    class="mt-3 rounded-md bg-[#EDAC24] px-6 py-3 text-sm font-semibold text-white shadow-sm border-2 border-[#EDAC24] hover:bg-transparent hover:text-[#EDAC24]">
                    <span class="mr-1">See the entire team</span>
                    <span aria-hidden="true"> →</span>
                </button>
            </div> -->
            <div class="rounded">
                <div class="flex flex-col lg:flex-row">
                    <div class="lg:w-1/3 py-4 pr-6">
                        <div class="inset-x-0 top-0 left-0 py-12">
                            <div class="text-3xl text-[#EDAC24] mb-8">Frequently asked questions.</div>
                            <h4 class="mb-2">More questions ?</h4>
                            <div class="text-gray-600">See our FAQ for more details</div>
                            <p class="text-gray-600 mt-2 ">
                                Get Fast, Affordable, Reliable Shipping with Self Ship Label. Sending packages worldwide has never been easier! With Self Ship Label, you're in control.
                            </p>

                            <div class="relative text-gray-600 mt-8 lg:mr-16">
                                <input type="text" name="search" v-model="searchText" placeholder="Search"
                                    class="bg-white w-full h-10 px-5 rounded-full text-sm focus:outline-none border border-[#EDAC24] focus:ring-[#EDAC24] focus:border-[#EDAC24]" />
                                <button type="button" @click="search"
                                    class="focus:outline-none absolute right-0 top-0 mt-2 mr-4">
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="lg:w-2/3 lg:py-8">
                        <div class="p-4">
                            <div v-for="(item, index) in itemData" :key="index"
                                class="item last:border-0 border-b border-[#EDAC24] py-6 overflow-hidden">
                                <a href="#" class="flex items-center justify-between" @click.prevent="toggleItem(index)">
                                    <h4 class="text-[#EDAC24] font-medium">{{ item.itemHeading }}</h4>
                                    <i
                                        :class="{ 'fa-solid fa-chevron-down transform rotate-180': item.itemCheck, 'fa-solid fa-chevron-down': !item.itemCheck }"></i>
                                </a>
                                <div class="overflow-hidden">
                                    <p class="mt-3 transition-all"
                                        :class="{ 'text-gray-600': item.itemCheck, 'h-0': !item.itemCheck }">
                                        {{ item.itemPara }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            itemData: [
                {
                    itemCheck: false,
                    itemHeading: "What is Self Ship Label?",
                    itemPara: "Our cutting-edge technology, Self Ship Label, gives users the freedom to independently handle their international shipments. For tasks like getting quotations, selecting couriers, making online payments, attaching labels, and more, it provides an easy-to-use interface.",
                },
                {
                    itemCheck: false,
                    itemHeading: "How can I use Self Ship Label to get started?",
                    itemPara: "It's simple to get going! All you have to do is create an account with Self Ship Label to access the Self Ship Label portal. With the help of our user-friendly tools, you can start creating and managing your shipments as soon as you log in. Happy Shipping!",
                },
                {
                    itemCheck: false,
                    itemHeading: "Is Self Ship Label available for international shipping ?",
                    itemPara: "Yes! The purpose of Self Ship Label is to facilitate both local and international shipping. Our platform offers everything you need for a smooth shipping experience, regardless of whether you're exporting locally or internationally.",
                },
                {
                    itemCheck: false,
                    itemHeading: "How does the shipping calculator work ?",
                    itemPara: "The destination, package weight, and dimensions are just a few of the details our shipping calculator uses to offer you instant quotations. It lets you select the shipping option that would save you the most money by comparing prices from different couriers.",
                },
                {
                    itemCheck: false,
                    itemHeading: "Do you have any extra or secret fees?",
                    itemPara: "Transparency is something we value. Our shipping calculator generates prices that include all applicable taxes and fees. There are no additional fees; you only pay what is shown.",
                },
                {
                    itemCheck: false,
                    itemHeading: "Which payment methods are available on Self Ship Label ?",
                    itemPara: "We accept multiple methods of payment, such as secure online payment platforms and popular credit cards. You can be sure that the highest level of security is being used for your transactions.",
                },
                {
                    itemCheck: false,
                    itemHeading: "Can I track my shipments with Self Ship Label ?",
                    itemPara: "Yes, you can! Every shipment processed through Self Ship Label comes with door-to-door tracking. Monitor the status of your package in real-time and stay informed throughout its journey.",
                },
                {
                    itemCheck: false,
                    itemHeading: "What if I need assistance during the shipping process ?",
                    itemPara: "Our customer support team is here to help! If you have any questions, concerns, or require assistance at any stage of the shipping process, feel free to reach out to our dedicated support team via the contact information provided on our website.",
                },
                {
                    itemCheck: false,
                    itemHeading: "How can I get a refund or make changes to my shipment ?",
                    itemPara: "For information on refunds or making changes to your shipment, please refer to our Terms and Conditions. If you need further assistance, contact our customer support, and we'll guide you through the process.",
                },
            ],
        };
    },
    methods: {
        toggleItem(index) {
            // console.log('click',this.$set(this.itemData, index, { ...this.itemData[index], itemCheck: !this.itemData[index].itemCheck }));
            this.itemData[index].itemCheck = !this.itemData[index].itemCheck;
        },
    },
};
</script>