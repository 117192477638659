<template>
    <LoaderComponent v-if="loading"></LoaderComponent>

    <div  class="w-full p-4 text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <!-- <div class="flex items-center justify-between pb-4">
            <div>
                <button id="dropdownRadioButton" data-dropdown-toggle="dropdownRadio"
                    class="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    type="button">
                    <svg class="w-3 h-3 text-gray-500 dark:text-gray-400 mr-2.5" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z" />
                    </svg>
                    Last 30 days
                    <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 1 4 4 4-4" />
                    </svg>
                </button>
                <div id="dropdownRadio"
                    class="z-10 hidden w-48 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
                    data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="top"
                    style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(522.5px, 3847.5px, 0px);">
                    <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownRadioButton">
                        <li>
                            <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                <input id="filter-radio-example-1" type="radio" value="" name="filter-radio"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="filter-radio-example-1"
                                    class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Last
                                    day</label>
                            </div>
                        </li>
                        <li>
                            <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                <input checked="" id="filter-radio-example-2" type="radio" value="" name="filter-radio"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="filter-radio-example-2"
                                    class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Last 7
                                    days</label>
                            </div>
                        </li>
                        <li>
                            <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                <input id="filter-radio-example-3" type="radio" value="" name="filter-radio"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="filter-radio-example-3"
                                    class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Last 30
                                    days</label>
                            </div>
                        </li>
                        <li>
                            <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                <input id="filter-radio-example-4" type="radio" value="" name="filter-radio"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="filter-radio-example-4"
                                    class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Last
                                    month</label>
                            </div>
                        </li>
                        <li>
                            <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                <input id="filter-radio-example-5" type="radio" value="" name="filter-radio"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="filter-radio-example-5"
                                    class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">Last
                                    year</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <label for="table-search" class="sr-only">Search</label>
            <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd"></path>
                    </svg>
                </div>
                <input type="text" id="table-search"
                    class="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search for items">
            </div>
        </div> -->

  <div class="relative overflow-x-auto rounded-lg">
        <table class="min-w-[1200px] w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase  dark:bg-gray-700 dark:text-gray-400 bg-[#F8EACA]">
                <tr>
                    <th scope="col" class="px-6 py-3">Sr.no.</th>
                    <th scope="col" class="px-6 py-3">Service</th>
                    <th scope="col" class="px-6 py-3">Package</th>
                    <th scope="col" class="px-6 py-3">Tracking No.</th>
                    <th scope="col" class="px-6 py-3">Payment</th>
                    <th scope="col" class="px-6 py-3">Action</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(pkg, index) in packages.data" :key="pkg.id">
                    <tr
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 font-medium text-gray-900">
                        <td class="px-6 py-4">
                            {{ ++index }}
                        </td>
                        <td scope="row" class="px-6 py-4 whitespace-nowrap dark:text-white">
                            {{ pkg.service_label }} <br>
                            {{ pkg.service_code }} <br>
                            {{ pkg.package_type_code }} <br>
                        </td>
                        <td class="px-6 py-4">
                            Package ID: {{ pkg.id }} <br>
                            <template v-for="box in pkg.boxes" :key="box.id">
                                <p>
                                    Dimension: {{ box.length }} x {{ box.width }} x {{ box.height }} {{ box.dim_unit }} <br>
                                    Weight: {{ box.weight }} {{ box.weight_unit }}
                                </p>
                            </template>
                        </td>
                        <td class="px-6 py-4">
                            {{ pkg.tracking_number_out }}
                        </td>
                        <td class="px-6 py-4">
                            {{ pkg.payment_status }}
                        </td>
                        <td class="px-6 py-4">
                            <template v-if="pkg.payment_status == 'Paid'">
                                <a :href="project_url + '/storage/labels/' + pkg.label_access_code + '.pdf'" target="_blank"
                                    class="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-2">Print
                                    Label</a>

                                <!-- <a :href="project_url + '/storage/labels/' + pkg.id + '.pdf'" target="_blank"
                                    class="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-2">Print
                                    Label</a> -->

                                <br>

                                <template v-for="(payment) in pkg.payments" :key="payment.id">
                                    <a :href="project_url + '/storage/payment-invoices/' + payment.id + '.pdf'"
                                        target="_blank"
                                        class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Print Invoice
                                        #{{ payment.id }} </a> <br>
                                </template>

                            </template>
                            <template v-else>
                                <router-link to="/shipment"
                                    class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</router-link>
                            </template>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        </div>

        <Pagination @fetchRecords="fetchRecords" :pagination="packages"></Pagination>

    </div>
</template>

<script>
import Pagination from "../Global/Pagination.vue";
import LoaderComponent from "../Partials/LoaderComponent.vue";

export default {
    name: "AccountOrder",
    data() {
        return {
            packages: [],
            loading: false,
            project_url: process.env.VUE_APP_PROJECT_URL,
        };
    },
    components: { Pagination, LoaderComponent },

    methods: {
        fetchRecords() {
            this.loading = true;
            const url = "package/index";
            this.$axios
                .post(url, null, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.packages = response.data.data.packages;
                    console.log(response);
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.fetchRecords();
    }
};
</script>