<template>

    <div class="flex justify-between items-center py-2 mb-4">
        <div>
            <h4 class="font-semibold text-2xl">Ticket List</h4>
        </div>
        <button @click="addInquiryFunc()" type="button"
            class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-black bg-[#ffb61a] hover:bg-[#e9ac4b] focus:outline-none text-base">
            <span class="font-semibold">Add Ticket</span>
        </button>
    </div>

    <div class="relative overflow-x-auto shadow-md rounded-lg">
        <table class="min-w-[1200px] w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

            <thead class="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400 bg-[#F8EACA]">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Sr.No.
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Ticket ID
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Subject
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Department
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Created At
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Status
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="inquiry, index in inquiries.data" :key="inquiry.id">
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-6 py-4">
                            {{ ++index }}
                        </td>
                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Ticket No #{{ inquiry.id }}
                        </th>
                        <td class="px-6 py-4">
                            {{ inquiry.subject }}
                        </td>
                        <td class="px-6 py-4">
                            {{ inquiry.department }}
                        </td>
                        <td class="px-6 py-4">
                            {{ formatDate(inquiry.created_at) }}
                        </td>
                        <td class="px-6 py-4">
                            <span v-if="inquiry.status === 'open'" class=" bg-blue-600 text-white px-4 py-1 rounded-lg text-sm">Open</span>
                            <span v-else class=" bg-red-400 text-white px-4 py-1 rounded-lg text-sm">Close</span>
                        </td>
                        <td class="px-6 py-4">

                            <router-link :to="`/inquery/${inquiry.id}?message=1`">
                             <button id="message-btn" type="button"
                                    class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-black focus:outline-none text-base">
                                    <i class="fa-solid fa-message"></i>
                                </button>
                            </router-link>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>

    <div v-if="addInquiry"
        class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 max-h-full bg-[rgba(11,11,11,0.5)]">
        <div class="relative p-4 w-full max-w-2xl max-h-full">
            <!-- Modal content -->
            <div class="relative bg-white rounded-lg shadow">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t bg-[#F8EACA]">
                    <h3 class="text-xl font-semibold text-gray-900">
                        New Ticket
                    </h3>
                    <button @click="addInquiryFunc" type="button"
                        class="text-gray-400 bg-transparent hover:bg-[#f0bc42] hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center ">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-4 md:p-5 space-y-4 max-h-[25rem] overflow-y-auto">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                        <div>
                            <label for="first_name"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                            <input type="text" id="first_name" v-model="form.name"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#FFD16E] focus:border-[#FFD16E] block w-full p-2.5 "
                                placeholder="" required readonly/>
                            <span class="text-red-600 text-base">{{ errors.name }}</span>
                        </div>
                        <div>
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Email</label>
                            <input type="email" id="email" v-model="form.email"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#FFD16E] focus:border-[#FFD16E] block w-full p-2.5 "
                                placeholder="" required readonly/>
                            <span class="text-red-600 text-base">{{ errors.email }}</span>

                        </div>
                    </div>

                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                        <div>
                            <label for="subject"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Subject</label>
                            <input type="text" id="subject" v-model="form.subject"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#FFD16E] focus:border-[#FFD16E] block w-full p-2.5 "
                                placeholder="" required />
                            <span class="text-red-600 text-base">{{ errors.subject }}</span>
                        </div>

                        <div>
                            <label for="department"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Department</label>
                            <input type="text" id="department" v-model="form.department"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#FFD16E] focus:border-[#FFD16E] block w-full p-2.5 "
                                placeholder="" required />
                            <span class="text-red-600 text-base">{{ errors.department }}</span>

                        </div>

                    </div>

                    <div>
                        <label for="message"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Message</label>
                        <textarea id="message" rows="5" v-model="form.message"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#FFD16E] focus:border-[#FFD16E] block w-full p-2.5 "
                            placeholder="" required></textarea>
                        <span class="text-red-600 text-base">{{ errors.message }}</span>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="flex items-center justify-end gap-3 p-4 md:p-5 border-t border-gray-200 rounded-b">
                    <button data-modal-hide="default-modal" type="button"
                        class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-100"
                        @click="closeModal">Cancel</button>
                    <button @click="submit()" type="button"
                        class="inline-flex items-center justify-center rounded-lg px-6 py-3 transition text-sm duration-500 ease-in-out text-black bg-[#ffb61a] hover:bg-[#e9ac4b] focus:outline-none">
                        <span class="font-medium">Submit</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { db } from '@/main';
import { collection,addDoc,serverTimestamp} from 'firebase/firestore';

export default {
    name: "AccountInquiries",
    data() {
        return {
            addInquiry: false,
            inquiries: [],
            loading: false,
            form: {
                name: "",
                email: "",
                subject: "",
                department: "",
                message: "",
            },
            errors: {}

        };
    },
    methods: {
        addInquiryFunc() {
            this.addInquiry = !this.addInquiry;
        },
        fetchRecords() {
            this.loading = true;
            const url = "inquiry/list";
            this.$axios
                .post(url, null, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.inquiries = response.data.data.inquiries;
                    this.form.name = response.data.data.user.name;
                    this.form.email = response.data.data.user.email;
                    console.log('s');
                    this.loading = false;
                })
                .catch((error) => {
                    alert(error)
                    console.log(error);
                    this.loading = false;
                });
        },
        
        submit() {
            this.loading = true;
            this.errors = {};
            this.$axios
                .post("inquiry/create", this.form, {
                    headers: this.$headers
                })
                .then((response) => {

                     this.sendMessage(response.data.message);
                    console.log(response.data.message)
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                })
                .finally(() => {
                    this.loading = false;
                    this.addInquiry = false;
                    this.fetchRecords();
                    this.form = {};
                });
        },
        closeModal() {
            this.addInquiry = false;
        },
        async  sendMessage(message) {
            this.loading = true;
            this.errors = {};
            var checkValue = this.form.message.trim();
            if(checkValue.length > 0){
            await addDoc(collection(db, 'InquiryMessages'), {
                inquiry_id: message.inquiry_id,
                user_id: message.user_id,
                user_type: 'customer',
                message: message.message,
                created_at: serverTimestamp(),
                updated_at: serverTimestamp()
            });


        }

        },
        formatDate(dateString) {
            const date = new Date(dateString);

            // Format the date
            const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const year = date.getFullYear();

            // Combine formatted parts
            return `${day}-${month}-${year}`;
        },
    },
    mounted() {
        this.fetchRecords();
    }
};
</script>

<style>
#message-btn{
    background-color:Orange;
}
/* #message-btn:hover {
  background-color: RoyalBlue;
} */

/* .fa-solid, .fas {
  color: white;
} */
</style>