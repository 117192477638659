<template>
    <footer class="bg-gradient-to-br from-[#FFD200] to-[#CD7D00] overflow-x-hidden">
        <div class="container xl:max-w-[1280px] mx-auto py-10 2xl:px-10 sm:px-5 px-3 grid grid-cols-4 lg:grid-cols-6">
            <div
                class="col-span-4 lg:col-span-2 text-center lg:text-left flex flex-col items-center lg:items-start justify-center lg:justify-start gap-4 3xl:gap-6">
                <div>
                    <router-link to="/" class="">
                        <img class="h-28 w-44 3xl:h-36 3xl:w-52 rounded-full object-contain" src="../assets/logo.svg"
                            alt="logo">
                    </router-link>
                </div>
                <p class="lg:max-w-[20rem] xl:max-w-md xl:text-base italic">
                    At Self Ship Label, our team is available 24/7 to address your inquiries and ensure your shipping
                    experience is smooth and efficient.
                </p>
                <div class="flex items-start gap-2">
                    <router-link to="/">
                        <img class="h-11 w-11 object-contain" src="../assets/images/fb.svg" alt="logo">
                    </router-link>
                    <router-link to="/">
                        <img class="h-11 w-11 object-contain" src="../assets/images/instagram.svg" alt="logo">
                    </router-link>
                    <router-link to="/">
                        <img class="h-11 w-11 object-contain" src="../assets/images/whatsapp.svg" alt="logo">
                    </router-link>
                    <router-link to="/">
                        <img class="h-11 w-11 object-contain" src="../assets/images/twitter.svg" alt="logo">
                    </router-link>
                </div>
            </div>
            <div class="col-span-4 grid grid-cols-2 md:grid-cols-4 gap-2 sm:gap-4 xl:gap-0 md:px-4 xl:px-0 pt-16">
                <div class="flex flex-col justify-start gap-2 md:gap-8">
                    <h4 class="font-semibold text-base xl:text-[1.2rem] text-[#2E2E2E]">Company</h4>
                    <div class="text-white flex flex-col gap-2 text-sm md:text-base">
                        <p> <router-link to="/about">About us</router-link></p>
                        <p> <router-link to="/contact-us">Contact us</router-link></p>
                        <!-- <p> <router-link to="/">Testimonial</router-link></p> -->
                    </div>
                </div>
                <div class="flex flex-col justify-start gap-2 md:gap-8">
                    <h4 class="font-semibold text-base xl:text-[1.2rem] text-[#2E2E2E]">Product</h4>
                    <div class="text-white flex flex-col gap-2 text-sm md:text-base">
                        <p><router-link to="/calculator">Calculator</router-link></p>
                        <p><router-link to="/faq">FAQs</router-link></p>
                    </div>
                </div>
                <div class="flex flex-col justify-start gap-2 md:gap-8">
                    <h4 class="font-semibold text-base xl:text-[1.2rem] text-[#2E2E2E]">Resources</h4>
                    <div class="text-white flex flex-col gap-2 text-sm md:text-base">
                        <p><router-link to="/shipping-restrictions">Shipping Restrictions</router-link></p>
                        <p><router-link to="/terms-and-conditions">Terms & Conditions</router-link></p>
                    </div>
                </div>
                <div class="flex flex-col justify-start gap-2 md:gap-8">
                    <h4 class="font-semibold text-base xl:text-[1.2rem] text-[#2E2E2E]">Contact us</h4>
                    <div class="text-white text-sm md:text-base flex flex-col gap-2">
                        <a href="tel:+1 (657) 210-1801"><p class="flex items-center gap-1 xl:gap-3"><img src="../assets/images/phone_icon.svg" alt=""><span>+1 (657) 210-1801</span></p></a>
                        <a href="mailto:info@selfshiplabel.com"><p class="flex items-center gap-1 xl:gap-3"><img src="../assets/images/mail_icon.svg" alt=""><span>info@selfshiplabel.com</span></p></a>
                        <a href="#" target="_blank"><p class="flex items-start gap-1 xl:gap-3"><img src="../assets/images/location_icon.svg" alt=""><span>Anaheim, Calfifornia, United States.</span></p></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>


<script>
export default {
    name: "FooterComponent",
    data() {
        return {
            latestYear: new Date().getFullYear().toString(),
        };
    },
};
</script>

