<template >

	<!-- <div class="  "> -->
		<div class="h-[100vh] fixed bg-[rgba(255,255,255,0.976)]  -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 inset-0 flex justify-center 
		items-center  z-50 w-full">
		<div class="loader"></div>
	</div>
	<!-- </div> -->
</template>

<style>
.loader {
	border: 10px solid #e5e5e5;
	border-top: 10px solid #EDAC24;
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
