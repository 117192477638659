<template>
    <button type="button" class="text-[#f7a900] font-medium rounded-lg text-sm py-2.5 text-center" @click="goBack">
        Previous
    </button>
</template>

<script>
export default {
    name: "BackButton",
    data() {
        return {
            // 
        };
    },
    methods: {
        goBack() {
            window.history.back();
        }
    },
    mounted() {
        // 
    },
};
</script>