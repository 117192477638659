<style>
.nav_item_active {
    background: linear-gradient(to left bottom, #FFD200, #CD7D00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.text-linear {
    background: linear-gradient(to left bottom, #FFD200, #CD7D00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}


.border-linear {
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #FFD200, #CD7D00) border-box;
    border-radius: 50em;
    border: 3px solid transparent;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: none;
    position: static;
}

.swiper-button-next,
.swiper-button-prev {
    position: static;
}

.swiper-button-prev:after {
    content: '';
}

.swiper-button-next:after {
    content: '';
}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 10px; */
}
</style>

<template>
    <main class="overflow-x-hidden">
        <!-- ============== HEADER =============  -->
        <header class="bg-gradient-to-br from-[#FFD200] to-[#CD7D00]">
            <div
                class="container xl:max-w-[1280px] mx-auto py-10 xl:py-5 2xl:py-10 3xl:py-16 2xl:pt-0 3xl:pt-0 xl:px-10 sm:px-5 px-5 grid grid-cols-1 md:grid-cols-2 justify-items-center md:justify-items-stretch gap-5 md:gap-0">

                <div data-aos="fade-right" class="flex flex-col justify-center">
                    <h3 class="md:text-lg 2xl:text-[22px] font-medium lg:leading-7 tracking-wide italic text-[#2E2E2E]">
                        <span class="text-white">Revolutionize</span> Your Shipping Process
                    </h3>
                    <h2
                        class="mt-1 md:mt-2 text-left text-[1.7rem] md:text-[2.1rem] lg:text-[2.45rem] xl:text-[2.65rem] 2xl:text-[2.85rem] leading-tight font-bold tracking-wider text-black">
                        Effortless Shipping with <span class="text-white">Self Ship
                            Label</span></h2>
                    <p
                        class="mt-2 lg:mt-3 xl:mt-6 2xl:text-lg lg:leading-8 text-white xl:max-w-2xl text-justify sm:text-left">
                        Transform your shipping workflow with Self Ship Label. Generate and print shipping labels
                        directly from your desktop, saving time and reducing costs. Our intuitive platform integrates
                        seamlessly with your e-commerce system, ensuring accurate shipments and real-time tracking.
                        Experience smarter shipping solutions with Self Ship Label today!</p>
                    <div class="mt-4 lg:mt-6 flex items-center gap-2 font-medium">
                        <router-link to="/shipment">
                            <button
                                class="px-8 py-1 xl:px-12 xl:py-2 text-[#CD7D00] bg-white border-2 border-white rounded-md hover:shadow-md transition-all">Ship
                                now</button>
                        </router-link>

                        <router-link to="/contact-us">
                            <button
                                class="px-8 py-1 xl:px-12 xl:py-2 text-white border-2 border-white rounded-md hover:shadow-md hover:bg-white hover:text-[#CD7D00] transition-all">Contact
                                us</button>
                        </router-link>
                    </div>
                </div>

                <div data-aos="fade-left" class="flex justify-end items-center">
                    <img src="../assets/images/home/header/header_boy.png" alt=""
                        class="w-[23rem] h-[23rem] lg:w-[28rem] lg:h-[28rem] xl:w-[30rem] xl:h-[30rem] 2xl:w-[32rem] 2xl:h-[33rem] 3xl:w-[36rem] 3xl:h-[36rem]">
                </div>

            </div>
        </header>
        <!-- ============== SECTION =============  -->
        <section>
            <div
                class="container xl:max-w-[1280px] mx-auto py-10 xl:py-10 2xl:py-10 3xl:py-16 xl:px-10 sm:px-5 px-5 grid grid-cols-1 md:grid-cols-2 justify-items-center md:justify-items-stretch gap-5 md:gap-0">

                <div data-aos="fade-right" class="order-2 md:order-none flex items-center justify-center">
                    <img src="../assets/images/home/about/delivery_box.png" alt="header_boy"
                        class="3xl:w-[30rem] 3xl:h-[31rem] 2xl:w-[28rem] 2xl:h-[29rem] xl:w-[26rem] xl:h-[26rem] lg:w-[24rem] lg:h-[24rem] sm:w-[18rem] sm:h-[18rem] w-[16rem] h-[16rem]">
                </div>

                <div data-aos="fade-left" class="flex flex-col justify-center">
                    <h3 class="md:text-lg 2xl:text-[22px] font-medium lg:leading-7 tracking-wide italic text-[#2E2E2E]">
                        <span class="text-linear">Experience</span> Effortless Shipping
                    </h3>
                    <h2
                        class="mt-1 md:mt-2 text-left text-[1.7rem] md:text-[2.1rem] lg:text-[2.45rem] xl:text-[2.65rem] 2xl:text-[2.85rem] leading-tight font-bold tracking-wider text-black">
                        All-in-One Shipping Made <span class="text-linear">Effortless</span>
                    </h2>
                    <p
                        class="mt-2 lg:mt-3 xl:mt-6 2xl:text-lg lg:leading-8 text-[#676767] xl:max-w-2xl text-justify sm:text-left">
                        Shipping packages worldwide is now a breeze with Self Ship Label. Take charge of your shipments
                        with our user-friendly platform, designed to simplify the process. Whether you're sending gifts
                        or managing global orders, Self Ship Label ensures a smooth, reliable experience from start to
                        finish. Trust us to handle your shipping needs with ease and confidence.</p>
                    <div class="mt-4 lg:mt-6 flex items-center gap-2 font-medium lg:h-[50px]">
                        <router-link to="/about"><button
                                class="bg-gradient-to-r from-[#FFD200] to-[#CD7D00] px-10 py-2 xl:px-12 xl:py-2.5 text-white rounded-md hover:shadow-md hover:rounded-sm transition-all">About</button></router-link>
                        <router-link to="/contact-us"><button
                                class="border-[3px] border-linear px-6 py-1.5 xl:px-10 xl:py-2 bg-white text-[#EEB500] rounded-md hover:bg-gradient-to-r hover:from-[#FFD200] hover:to-[#CD7D00] hover:text-white hover:py-2.5 hover:px-11 hover:shadow-md hover:rounded-sm hover:border-0 transition-all">Contact
                                us</button></router-link>
                    </div>
                </div>

            </div>
        </section>
        <!-- ============== SECTION =============  -->
        <section>
            <div class="container xl:max-w-[1280px] mx-auto pb-10 pt-10 3xl:pt-20 3xl:pb-20 2xl:px-10 sm:px-5 px-5 ">

                <div class="flex flex-col justify-center text-center">
                    <h3 class="md:text-lg 2xl:text-[22px] font-medium lg:leading-7 tracking-wide italic text-[#676767]">
                        <span class="text-linear">Navigate</span> Shipping with Ease
                    </h3>
                    <h2
                        class="mt-1 md:mt-2 text-[1.7rem] md:text-[2.1rem] lg:text-[2.45rem] xl:text-[2.65rem] 2xl:text-[2.85rem] leading-tight font-bold tracking-wider text-black">
                        How to Ship with Ease: <span class="text-linear">Our Simple Process</span></h2>
                </div>

                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 2xl:gap-4 3xl:gap-4 py-10 2xl:py-10">

                    <div data-aos="fade-right"
                        class="border-linear rounded-[3rem] outline-[3px] outline outline-gray-300 -outline-offset-[15px] p-4 xl:p-5 2xl:p-8 py-6 xl:py-10 2xl:py-12 flex flex-col items-center gap-8 hover:shadow-lg cursor-pointer transition-all hover:outline-white hover:bg-gradient-to-br from-[#FFD200] to-[#CD7D00] group">
                        <img class="w-28 h-28 xl:w-36 xl:h-36 2xl:w-40 2xl:h-40"
                            src="../assets/images/home/working/process1.svg" alt="process1">
                        <div class="text-center">
                            <h4
                                class="font-semibold text-lg xl:text-xl 2xl:text-xl text-[#2E2E2E] group-hover:text-white">
                                Enter Shipping
                                Details</h4>
                            <p class="mt-1 xl:mt-2 text-[#676767] group-hover:text-gray-100">Input your package’s
                                origin,
                                destination, weight, and
                                dimensions to get accurate shipping options.</p>
                        </div>
                    </div>
                    <div data-aos="fade-up"
                        class="border-linear rounded-[3rem] outline-[3px] outline outline-gray-300 -outline-offset-[15px] p-4 xl:p-5 2xl:p-8 py-6 xl:py-10 2xl:py-12 flex flex-col items-center gap-8 hover:shadow-lg cursor-pointer transition-all hover:outline-white hover:bg-gradient-to-br from-[#FFD200] to-[#CD7D00] group">
                        <img class="w-28 h-28 xl:w-36 xl:h-36 2xl:w-40 2xl:h-40"
                            src="../assets/images/home/working/process2.svg" alt="process2">
                        <div class="text-center">
                            <h4
                                class="font-semibold text-lg xl:text-xl 2xl:text-xl text-[#2E2E2E] group-hover:text-white">
                                Choose a Courier
                            </h4>
                            <p class="mt-1 xl:mt-2 text-[#676767] group-hover:text-gray-100">Select from top couriers
                                like
                                FedEx, DHL, or UPS to
                                find the best service for your needs.</p>
                        </div>
                    </div>
                    <div data-aos="fade-up"
                        class="border-linear rounded-[3rem] outline-[3px] outline outline-gray-300 -outline-offset-[15px] p-4 xl:p-5 2xl:p-8 py-6 xl:py-10 2xl:py-12 flex flex-col items-center gap-8 hover:shadow-lg cursor-pointer transition-all hover:outline-white hover:bg-gradient-to-br from-[#FFD200] to-[#CD7D00] group">
                        <img class="w-28 h-28 xl:w-36 xl:h-36 2xl:w-40 2xl:h-40"
                            src="../assets/images/home/working/process3.svg" alt="process3">
                        <div class="text-center">
                            <h4
                                class="font-semibold text-lg xl:text-xl 2xl:text-xl text-[#2E2E2E] group-hover:text-white">
                                Pay Securely Online
                            </h4>
                            <p class="mt-1 xl:mt-2 text-[#676767] group-hover:text-gray-100">Complete your payment
                                through
                                our secure platform to
                                finalize your shipping.</p>
                        </div>
                    </div>
                    <div data-aos="fade-left"
                        class="border-linear rounded-[3rem] outline-[3px] outline outline-gray-300 -outline-offset-[15px] p-5 2xl:p-8 py-6 xl:py-10 2xl:py-12 flex flex-col items-center gap-8 hover:shadow-lg cursor-pointer transition-all hover:outline-white hover:bg-gradient-to-br from-[#FFD200] to-[#CD7D00] group">
                        <img class="w-28 h-28 xl:w-36 xl:h-36 2xl:w-40 2xl:h-40"
                            src="../assets/images/home/working/process4.svg" alt="process4">
                        <div class="text-center">
                            <h4
                                class="font-semibold text-lg xl:text-xl 2xl:text-xl text-[#2E2E2E] group-hover:text-white">
                                Attach Label & Ship
                            </h4>
                            <p class="mt-1 xl:mt-2 text-[#676767] group-hover:text-gray-100">Print and attach your
                                shipping
                                label, then drop off
                                your package or schedule a convenient pickup.</p>
                        </div>
                    </div>

                </div>

            </div>
        </section>
        <!-- ============== SECTION =============  -->
        <section class="bg-[#FFEDD4]">
            <div class="container xl:max-w-[1280px] mx-auto py-5 2xl:py-10 2xl:pb-0 pb-0 2xl:px-10 sm:px-5 px-5">

                <div class="flex flex-col justify-center text-center">
                    <h3 class="md:text-lg 2xl:text-[22px] font-medium lg:leading-7 tracking-wide italic text-[#2E2E2E]">
                        <span class="text-linear">Empowered</span> by Leading Shipping Experts
                    </h3>
                    <h2
                        class="mt-1 md:mt-2 text-[1.7rem] md:text-[2.1rem] lg:text-[2.45rem] xl:text-[2.65rem] 2xl:text-[2.85rem] leading-tight font-bold tracking-wider text-black">
                        Leading Brands We Work With </h2>
                </div>

                <div
                    class="grid grid-cols-2 lg:grid-cols-5 justify-items-stretch gap-4 lg:gap-4 2xl:gap-6 3xl:gap-4 pt-6 sm:pt-6 2xl:pt-8 pb-0 sm:pb-12">

                    <div data-aos="fade-right" class="flex justify-center">
                        <img class="w-[10rem] md:w-[12rem]" src="../assets/images/home/brands/brand1.svg" alt="brand1">
                    </div>
                    <div data-aos="fade-up" class="flex justify-center">
                        <img class="w-[10rem] md:w-[12rem]" src="../assets/images/home/brands/brand2.svg" alt="brand2">
                    </div>
                    <div data-aos="fade-up" class="flex justify-center">
                        <img class="object-contain md:w-[12rem]" src="../assets/images/home/brands/brand3.png"
                            alt="brand3">
                    </div>
                    <div data-aos="fade-up" class="flex justify-center">
                        <img class="w-[10rem] md:w-[10rem]" src="../assets/images/home/brands/brand3.svg" alt="brand4">
                    </div>
                    <div data-aos="fade-left" class="flex justify-center">
                        <img class="w-[10rem] md:w-[10rem]" src="../assets/images/home/brands/brand4.svg" alt="brand4">
                    </div>

                </div>

            </div>
        </section>
        <!-- ============== SECTION =============  -->
        <section>
            <div
                class="container xl:max-w-[1280px] mx-auto py-5 xl:py-5 2xl:py-5 pb-0 2xl:pb-0 xl:px-10 sm:px-5 px-5 grid grid-cols-1 md:grid-cols-2 justify-items-center md:justify-items-stretch gap-5 md:gap-0">

                <div data-aos="fade-right" class="flex flex-col justify-center">
                    <h3 class="md:text-lg 2xl:text-[22px] font-medium lg:leading-7 tracking-wide italic text-[#2E2E2E]">
                        <span class="text-linear">Experience</span> Best Shipping Solutions
                    </h3>
                    <h2
                        class="mt-1 md:mt-2 text-left text-[1.7rem] md:text-[2.1rem] lg:text-[2.45rem] xl:text-[2.65rem] 2xl:text-[2.85rem] leading-tight font-bold tracking-wider text-black">
                        Service Features & <br class="hidden md:block"> <span class="text-linear">How We Deliver</span>
                    </h2>
                    <p
                        class="mt-2 lg:mt-3 xl:mt-6 2xl:text-lg lg:leading-8 text-[#676767] xl:max-w-2xl text-justify sm:text-left">
                        Discover how our platform leverages partnerships with top carriers like FedEx, DHL, and UPS to
                        provide you with the best parcel delivery options all in one place. Our innovative solutions and
                        streamlined processes ensure you get reliable, cost-effective shipping solutions, handling
                        everything from start to finish for a hassle-free experience.</p>
                    <div class="mt-4 lg:mt-6 flex items-center gap-2 font-medium lg:h-[50px]">
                        <router-link to="/about"><button
                                class="bg-gradient-to-r from-[#FFD200] to-[#CD7D00] px-10 py-2 xl:px-12 xl:py-2.5 text-white rounded-md hover:shadow-md hover:rounded-sm transition-all">About</button></router-link>
                        <router-link to="/contact-us"><button
                                class="border-[3px] border-linear px-6 py-1.5 xl:px-10 xl:py-2 bg-white text-[#EEB500] rounded-md hover:bg-gradient-to-r hover:from-[#FFD200] hover:to-[#CD7D00] hover:text-white hover:py-2.5 hover:px-11 hover:shadow-md hover:rounded-sm hover:border-0 transition-all">Contact
                                us</button></router-link>
                    </div>
                </div>

                <div data-aos="fade-left" class="flex justify-end">
                    <img src="../assets/images/home/feature/rider_boy.svg" alt="rider_boy"
                        class="3xl:w-[40rem] 3xl:h-[36rem] 2xl:w-[34rem] 2xl:h-[30rem] lg:w-[32rem] lg:h-[28rem] sm:w-[28rem] sm:h-[24rem] h-[22rem] w-[35rem]">
                </div>

            </div>
        </section>
        <!-- ============== SECTION =============  -->
        <section>
            <div
                class="container xl:max-w-[1280px] mx-auto py-10 xl:py-5 2xl:py-10 xl:px-10 sm:px-5 px-5 grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-0">

                <div
                    class="order-2 md:order-none flex full justify-center flex-row px-0 sm:p-4 h-full py-5 md:py-8 items-center">
                    <div
                        class="xl:pr-12 flex w-full flex-col gap-6 md:gap-8 md:justify-center md:items-center sm:justify-center">
                        <div data-aos="fade-right"
                            class="grid grid-cols-1 3xl:grid-cols-[55%,45%] xl:grid-cols-[65%,35%] md:grid-cols-[90%,10%] rounded-full w-full text-[#2c2c2c]">
                            <div
                                class="rounded-full border shadow-md bg-white py-2 sm:py-2 px-2 flex justify-between items-center gap-8">
                                <div class="w-12 h-8 3xl:w-12 3xl:h-10 flex items-center justify-center rounded-full">
                                    <img class="3xl:w-12 sm:w-10 w-8" alt="track1" loading="lazy"
                                        src="../assets/images/home/tracking/track1.svg" />
                                </div>
                                <div class="w-[100%] text-center">
                                    <h3 class="font-semibold text-sm lg:text-base text-left">Control &
                                        Customization</h3>
                                </div>
                            </div>
                            <div class=" hidden md:block"></div>
                        </div>
                        <div data-aos="fade-left"
                            class="grid grid-cols-1 3xl:grid-cols-[40%,60%] xl:grid-cols-[35%,65%] md:grid-cols-[10%,90%] rounded-full w-full">
                            <div class="hidden md:block"></div>
                            <div
                                class="rounded-full border shadow-md bg-white py-2 sm:py-2 px-2 flex justify-between items-center gap-7">
                                <div class="w-[85%] md:w-[85%] text-center">
                                    <h3 class="font-semibold text-sm lg:text-base text-left pl-2">Easy &
                                        Transparent Tracking</h3>
                                </div>
                                <div class="w-12 h-8 3xl:w-10 3xl:h-8 flex items-center justify-center rounded-full">
                                    <img class="3xl:w-7 sm:w-6 w-5" alt="track2" loading="lazy"
                                        src="../assets/images/home/tracking/track2.svg" />
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-right"
                            class="grid grid-cols-1 3xl:grid-cols-[55%,45%] xl:grid-cols-[65%,35%] md:grid-cols-[90%,10%] rounded-full w-full">
                            <div
                                class="rounded-full border shadow-md bg-white py-2 sm:py-2 px-2 flex justify-between items-center gap-8">
                                <div class="w-10 h-7 3xl:w-12 3xl:h-8 flex items-center justify-center rounded-full">
                                    <img class="3xl:w-10 sm:w-8 w-6" alt="track3" loading="lazy"
                                        src="../assets/images/home/tracking/track3.svg" />
                                </div>
                                <div class=" w-[70%] text-center">
                                    <h3 class="font-semibold text-sm lg:text-base">Cost & Time
                                        Efficient</h3>
                                </div>
                            </div>
                            <div class=" hidden md:block"></div>
                        </div>
                        <div data-aos="fade-left"
                            class="grid grid-cols-1 3xl:grid-cols-[40%,60%] xl:grid-cols-[35%,65%] md:grid-cols-[10%,90%] rounded-full w-full">
                            <div class="hidden md:block"></div>
                            <div
                                class="rounded-full border shadow-md bg-white py-2 sm:py-2 px-2 flex justify-between items-center gap-8">
                                <div class="w-[85%] md:w-[70%] text-center">
                                    <h3 class="font-semibold text-sm lg:text-base text-left pl-2">Global & Local
                                        Care</h3>
                                </div>
                                <div class="w-12 h-8 3xl:w-14 3xl:h-10 flex items-center justify-center rounded-full">
                                    <img class="3xl:w-12 sm:w-10 w-8" alt="track4" loading="lazy"
                                        src="../assets/images/home/tracking/track4.svg" />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div data-aos="fade-left" class="flex flex-col justify-center">
                    <h3 class="md:text-lg 2xl:text-[22px] font-medium lg:leading-7 tracking-wide italic text-[#2E2E2E]">
                        <span class="text-linear">Effortless </span> Shipping Solutions for All Your Needs
                    </h3>
                    <h2
                        class="mt-1 md:mt-2 text-left text-[1.7rem] md:text-[2.1rem] lg:text-[2.45rem] xl:text-[2.65rem] 2xl:text-[2.85rem] leading-tight font-bold tracking-wider text-black">
                        Experience Effortless <br class="hidden xl:block"> <span class="text-linear">Shipping
                            Control</span></h2>
                    <p
                        class="mt-2 lg:mt-3 xl:mt-6 2xl:text-lg lg:leading-8 text-[#676767] xl:max-w-2xl text-justify sm:text-left">
                        Transform your shipping workflow with Self Ship Label. Generate and print shipping labels
                        directly from your desktop, saving time and reducing costs. Our intuitive platform integrates
                        seamlessly with your e-commerce system, ensuring accurate shipments and real-time tracking.
                        Experience smarter shipping solutions with Self Ship Label today!</p>
                    <div class="mt-4 lg:mt-6 flex items-center gap-2 font-medium lg:h-[50px]">
                        <router-link to="/about"><button
                                class="bg-gradient-to-r from-[#FFD200] to-[#CD7D00] px-10 py-2 xl:px-12 xl:py-2.5 text-white rounded-md hover:shadow-md hover:rounded-sm transition-all">About</button></router-link>
                        <router-link to="/contact-us"><button
                                class="border-[3px] border-linear px-6 py-1.5 xl:px-10 xl:py-2 bg-white text-[#EEB500] rounded-md hover:bg-gradient-to-r hover:from-[#FFD200] hover:to-[#CD7D00] hover:text-white hover:py-2.5 hover:px-11 hover:shadow-md hover:rounded-sm hover:border-0 transition-all">Contact
                                us</button></router-link>
                    </div>
                </div>

            </div>
        </section>
        <!-- ============== SECTION =============  -->
        <section class="bg-[#FFEDD4]">
            <div
                class="container xl:max-w-[1280px] mx-auto py-10 xl:py-5 2xl:py-10 xl:px-10 sm:px-5 px-5 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-0">

                <div data-aos="fade-right" class="flex flex-col justify-center">
                    <h3 class="md:text-lg 2xl:text-[22px] font-medium lg:leading-7 tracking-wide italic text-[#2E2E2E]">
                        With our <span class="text-linear">‘Super powers’</span> we have reached this</h3>
                    <h2
                        class="mt-1 md:mt-2 text-left text-[1.7rem] md:text-[2.1rem] lg:text-[2.45rem] xl:text-[2.65rem] 2xl:text-[2.85rem] leading-tight font-bold tracking-wider text-black">
                        Progress and Performance <span class="text-linear">Highlights</span>
                    </h2>
                </div>

                <div class="py-4 grid grid-cols-2 gap-y-5">

                    <div data-aos="fade-left"
                        class="py-2 xl:py-4 flex sm:items-start xl:items-center sm:flex-row flex-col items-center sm:text-left text-center gap-3">
                        <div><img class="w-16 h-16" src="../assets/images/home/highlights/high1.svg" alt="high1"></div>
                        <div class="text-[#2E2E2E]">
                            <h4 class="text-3xl 2xl:text-4xl font-bold">2K+</h4>
                            <p class="2xl:text-lg">Shipments Completed</p>
                        </div>
                    </div>
                    <div data-aos="fade-left"
                        class="py-2 xl:py-4 flex sm:items-start xl:items-center sm:flex-row flex-col items-center sm:text-left text-center gap-3">
                        <div><img class="w-16 h-16" src="../assets/images/home/highlights/high2.svg" alt="high2"></div>
                        <div class="text-[#2E2E2E]">
                            <h4 class="text-3xl 2xl:text-4xl font-bold">2+</h4>
                            <p class="2xl:text-lg">Years of Experience</p>
                        </div>
                    </div>
                    <div data-aos="fade-up"
                        class="py-2 xl:py-4 flex sm:items-start xl:items-center sm:flex-row flex-col items-center sm:text-left text-center gap-3">
                        <div><img class="w-16 h-16" src="../assets/images/home/highlights/high3.svg" alt="high3"></div>
                        <div class="text-[#2E2E2E]">
                            <h4 class="text-3xl 2xl:text-4xl font-bold">2K+</h4>
                            <p class="2xl:text-lg">Happy Clients</p>
                        </div>
                    </div>
                    <div data-aos="fade-up"
                        class="py-2 xl:py-4 flex sm:items-start xl:items-center sm:flex-row flex-col items-center sm:text-left text-center gap-3">
                        <div><img class="w-16 h-16" src="../assets/images/home/highlights/high4.svg" alt="high4"></div>
                        <div class="text-[#2E2E2E]">
                            <h4 class="text-3xl 2xl:text-4xl font-bold">284</h4>
                            <p class="2xl:text-lg">New Orders on the Way</p>
                        </div>
                    </div>

                </div>

            </div>
        </section>
        <!-- ============== SECTION =============  -->
        <section>
            <div class="container xl:max-w-[1280px] mx-auto py-10 xl:py-5 2xl:py-10 xl:px-10 sm:px-5 px-5">

                <div class="flex flex-col justify-center text-center">
                    <h3 class="md:text-lg 2xl:text-[22px] font-medium lg:leading-7 tracking-wide italic text-[#2E2E2E]">
                        <span class="text-linear">See</span> How We Stand Out
                    </h3>
                    <h2
                        class="mt-1 md:mt-2 text-[1.7rem] md:text-[2.1rem] lg:text-[2.45rem] xl:text-[2.65rem] 2xl:text-[2.85rem] leading-tight font-bold tracking-wider text-black">
                        Why Choose <span class="text-linear">Us</span></h2>
                </div>
                <div class="pt-10 overflow-x-auto">
                    <div
                        class="grid grid-cols-7 gap-y-3 gap-4 3xl:gap-4 px-2 3xl:px-5 py-14 border rounded-2xl text-[#676767] min-w-[1200px]">

                        <div class="flex flex-col items-center rounded-2xl text-center">
                            <div class="p-4 lg:py-6 w-full flex items-center justify-center h-28 opacity-0">

                            </div>
                            <div class="rounded-2xl w-full xl:p-3 bg-white">
                                <div class="flex flex-col items-center font-semibold rounded-2xl w-full text-sm">
                                    <p class="w-full py-3 px-2 xl:px-5">Membership </p>
                                    <p class="w-full py-3 px-2 xl:px-5">Multipiece</p>
                                    <p class="w-full py-3 px-2 xl:px-5">Fedex Label</p>
                                    <p class="w-full py-3 px-2 xl:px-5">DHL Labels</p>
                                    <p class="w-full py-3 px-2 xl:px-5">UPS Labels</p>
                                    <p class="w-full py-3 px-2 xl:px-5">USPS Labels</p>
                                    <p class="w-full py-3 px-2 xl:px-5">Mulitshipment</p>
                                    <p class="w-full py-3 px-2 xl:px-5">Insurance</p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex flex-col items-center bg-gradient-to-br from-[#FFD200] to-[#CD7D00] rounded-2xl text-center">
                            <div class="p-4 lg:py-6 w-full flex items-center justify-center h-24">
                                <img class="w-24 h-12 object-contain" src="../assets/images/home/choose/brand1.svg"
                                    alt="brand1">
                            </div>
                            <div class="rounded-2xl w-full xl:p-2 2xl:p-3 bg-white">
                                <div class="flex flex-col items-center font-medium border rounded-2xl w-full">
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                </div>
                            </div>
                        </div>

                        <div
                            class="flex flex-col items-center bg-gradient-to-br from-[#FFD200] to-[#CD7D00] rounded-2xl text-center">
                            <div class="p-4 lg:py-6 w-full flex items-center justify-center h-24">
                                <img class="w-24 h-12 object-contain" src="../assets/images/home/choose/brand3.svg"
                                    alt="brand3">
                            </div>
                            <div class="rounded-2xl w-full xl:p-2 2xl:p-3 bg-white">
                                <div class="flex flex-col items-center font-medium border rounded-2xl w-full">
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex flex-col items-center bg-gradient-to-br from-[#FFD200] to-[#CD7D00] rounded-2xl text-center">
                            <div class="p-4 lg:py-6 w-full flex items-center justify-center h-24">
                                <img class="w-28 h-12 object-contain" src="../assets/images/home/choose/brand4.svg"
                                    alt="brand4">
                            </div>
                            <div class="rounded-2xl w-full xl:p-2 2xl:p-3 bg-white">
                                <div class="flex flex-col items-center font-medium border rounded-2xl w-full">
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex flex-col items-center bg-gradient-to-br from-[#FFD200] to-[#CD7D00] rounded-2xl text-center">
                            <div class="p-4 py-6 w-full flex items-center justify-center h-24">
                                <img class="w-24 h-12 object-contain" src="../assets/images/home/choose/brand5.svg"
                                    alt="brand5">
                            </div>
                            <div class="rounded-2xl w-full xl:p-2 2xl:p-3 bg-white">
                                <div class="flex flex-col items-center font-medium border rounded-2xl w-full">
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex flex-col items-center bg-gradient-to-br from-[#FFD200] to-[#CD7D00] rounded-2xl text-center">
                            <div class="p-4 lg:py-6 w-full flex items-center justify-center h-24">
                                <img class="w-24 h-12 object-contain" src="../assets/images/home/choose/brand6.svg"
                                    alt="brand6">
                            </div>
                            <div class="rounded-2xl w-full xl:p-2 2xl:p-3 bg-white">
                                <div class="flex flex-col items-center font-medium border rounded-2xl w-full">
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex flex-col items-center bg-gradient-to-br from-[#FFD200] to-[#CD7D00] rounded-2xl text-center">
                            <div class="p-4 py-6 w-full flex items-center justify-center h-24">
                                <img class="w-24 h-12 object-contain" src="../assets/images/home/choose/brand7.svg"
                                    alt="brand7">
                            </div>
                            <div class="rounded-2xl w-full xl:p-2 2xl:p-3 bg-white">
                                <div class="flex flex-col items-center font-medium border rounded-2xl w-full">
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5 bg-[#FFEDD4]"><i
                                            class="fa-solid fa-xmark text-red-700 text-xl"></i></p>
                                    <p class="w-full py-3 px-2 xl:px-5"><i
                                            class="fa-solid fa-circle-check text-green-800 text-xl"></i></p>
                                </div>
                            </div>
                        </div>

                        <div class="xl:col-start-2 col-span-6 xl:col-span-6 flex shadow-inner rounded-2xl">
                            <div
                                class="h-full py-5 3xl:py-3 font-semibold md:text-base rounded-l-2xl bg-gradient-to-br from-[#FFD200] to-[#CD7D00] text-white px-4 flex justify-center items-center shadow-inner">
                                Recommendation
                            </div>
                            <div
                                class="h-full w-full py-5 3xl:py-3 rounded-r-2xl bg-[#FFEDD4] text-[#676767] flex items-center gap-4 px-4 shadow-inner">
                                <h5 class="font-bold text-3xl md:text-[2.4rem] text-[#2E2E2E]">3K+</h5>
                                <p class="md:text-base whitespace-normal">of customers recommend Self Ship Label,
                                    because of
                                    above market benefits.</p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
        <!-- ============== SECTION =============  -->
        <section class="py-10 3xl:py-20 3xl:pt-10 xl:px-10 sm:px-5 px-5 overflow-hidden">
            <div class="py-10 2xl:px-10 sm:px-5 px-5 bg-gradient-to-br from-[#FFD200] to-[#CD7D00] rounded-3xl">
                <div class="sm:p-3 lg:p-5 3xl:p-8">
                    <div class="text-center z-10 lg:z-0">
                        <h3
                            class="md:text-lg 2xl:text-[22px] font-medium lg:leading-7 tracking-wide italic text-[#2E2E2E]">
                            <span class="text-white">What</span> our customers are saying
                        </h3>
                        <h2
                            class="mt-1 md:mt-2 text-[1.7rem] md:text-[2.1rem] lg:text-[2.45rem] xl:text-[2.65rem] 2xl:text-[2.85rem] leading-tight font-bold tracking-wider text-black">
                            Our Customers’ <span class="text-white">Feedback</span></h2>
                    </div>

                    <div class="grid grid-cols-1 lg:grid-cols-2 xl:gap-5 py-10 xl:py-12 xl:pt-16 px-0 3xl:px-12">
                        <div data-aos="fade-right" class=" flex items-center justify-center relative">
                            <img class="w-full lg:w-[50rem] 2xl:w-full absolute lg:static -top-5"
                                src="../assets/images/home/feedback/feedback_map.svg" alt="feedback_map">
                        </div>

                        <div data-aos="fade-left" :navigation="{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev'
                        }" :modules="modules"
                            class="mySwiper z-10 lg:z-0 px-0 lg:px-5 xl:px-10 2xl:px-20 3xl:px-28 flex flex-col justify-center gap-8">
                            <swiper :spaceBetween="20" ref="swiperRef" @swiper="onSwiper">
                                <swiper-slide
                                    class="border bg-white !rounded-2xl px-5 py-8 sm:px-8 sm:py-12  lg:px-8 lg:py-8 flex flex-col md:flex-row gap-3 items-start">
                                    <div class="w-52">
                                        <img class="w-20 rounded-full" src="../assets/images/user.jpg" alt="user">
                                    </div>
                                    <div class="text-[#676767] flex flex-col gap-3 text-left">
                                        <h6 class="text-xl md:text-2xl font-semibold text-[#2E2E2E]">Jessica Lee.</h6>
                                        <div class="flex items-center gap-2  text-[#FFD200]">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                        </div>
                                        <p class="md:text-lg italic text-justify sm:text-left">Self Ship Label made my
                                            shipping process so seamless! The labels were easy to print and the
                                            international shipping was handled perfectly. Highly recommend for anyone
                                            needing reliable and efficient shipping solutions!</p>
                                    </div>

                                </swiper-slide>

                                <swiper-slide
                                    class="border bg-white !rounded-2xl px-5 py-8 sm:px-8 sm:py-12  lg:px-8 lg:py-8 flex flex-col md:flex-row gap-3 items-start">
                                    <div class="w-52">
                                        <img class="w-20 rounded-full" src="../assets/images/user.jpg" alt="user">
                                    </div>
                                    <div class="text-[#676767] flex flex-col gap-3 text-left">
                                        <h6 class="text-xl md:text-2xl font-semibold text-[#2E2E2E]">Michael Rodriguez.</h6>
                                        <div class="flex items-center gap-2  text-[#FFD200]">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                        </div>
                                        <p class="md:text-lg italic text-justify sm:text-left">I’ve used Self Ship Label
                                            for both domestic and international shipments, and every experience has been
                                            top-notch. The process is straightforward, and the customer service is
                                            exceptional. Five stars from me!</p>
                                    </div>
                                </swiper-slide>

                                <swiper-slide
                                    class="border bg-white !rounded-2xl px-5 py-8 sm:px-8 sm:py-12  lg:px-8 lg:py-8 flex flex-col md:flex-row gap-3 items-start">
                                    <div class="w-52">
                                        <img class="w-20 rounded-full" src="../assets/images/user.jpg" alt="user">
                                    </div>
                                    <div class="text-[#676767] flex flex-col gap-3 text-left">
                                        <h6 class="text-xl md:text-2xl font-semibold text-[#2E2E2E]">Brian Watson.</h6>
                                        <div class="flex items-center gap-2  text-[#FFD200]">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                            <img class="w-5" src="../assets/images/home/feedback/star.svg" alt="star">
                                        </div>
                                        <p class="md:text-lg italic text-justify sm:text-left">I recently started using
                                            Self Ship Label for my small business, and it has been a game-changer. The
                                            convenience of managing both domestic and international labels from one
                                            place is invaluable. Absolutely fantastic service!</p>
                                    </div>
                                </swiper-slide>
                            </swiper>

                            <div class="relative flex justify-center items-center gap-4">
                                <button @click="swiperPrevSlide"
                                    class="swiper-button-prev flex items-center justify-center bg-white rounded-full shadow-lg h-14 w-14 hover:shadow-xl"><img
                                        src="../assets/images/home/feedback/arrow-left.svg" alt="arrow_left"></button>
                                <button @click="swiperNextSlide"
                                    class="swiper-button-next flex items-center justify-center bg-white rounded-full shadow-lg h-14 w-14 hover:shadow-xl"><img
                                        src="../assets/images/home/feedback/arrow-right.svg" alt="arrow_left"></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div data-aos="fade-up"
                    class="flex flex-col justify-center items-center text-center border shadow-md bg-white rounded-2xl px-5 py-10 sm:py-10 3xl:py-14">
                    <h3 class="md:text-lg 2xl:text-[22px] font-medium lg:leading-7 tracking-wide italic text-[#2E2E2E]">
                        <span class="text-linear">No</span> Sign-Up Needed
                    </h3>
                    <h2
                        class="mt-1 md:mt-2 text-[1.7rem] md:text-[2.1rem] lg:text-[2.45rem] xl:text-[2.65rem] 2xl:text-[2.85rem] leading-tight font-bold tracking-wider text-black">
                        Get an Instant <span class="text-linear">Quote</span></h2>
                    <p
                        class="mt-6 md:text-lg leading-8 text-[#676767] md:max-w-5xl text-justify sm:text-center xl:px-28">
                        With
                        Self Ship Label, unlock incredible discounts of up to 88% on your shipping rates for free. Enjoy
                        cost and time-efficient solutions, thanks to our partnerships with leading delivery services
                        like FedEx, UPS, and more.</p>
                    <div class="mt-4 xl:mt-6 flex items-center gap-2 font-medium lg:h-[50px]">
                        <router-link to="/calculator"><button
                                class="bg-gradient-to-r from-[#FFD200] to-[#CD7D00] px-10 py-2 xl:px-12 xl:py-2.5 text-white rounded-md hover:shadow-md hover:rounded-sm transition-all">Get
                                Rates</button></router-link>
                    </div>
                </div>

            </div>
        </section>
    </main>
</template>


<script>

// import NavComponent from './NavComponent.vue';
import { ref, onMounted } from 'vue';

import AOS from 'aos';
import 'aos/dist/aos.css';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';

export default {
    methods: {
        Navigation() {
            return Navigation
        }
    },
    components: {
        Swiper,
        SwiperSlide,
        // NavComponent,
    },
    setup() {
        const swiperInstance = ref()

        function onSwiper(swiper) {
            swiperInstance.value = swiper
        }
        const swiperNextSlide = () => {
            swiperInstance.value.slideNext()
        };
        const swiperPrevSlide = () => {
            swiperInstance.value.slidePrev()
        };
        onMounted(() => {
            AOS.init({
                duration: 1200,
                easing: 'ease-in-out',
                // once: true,
            });
        });
        return {
            swiperPrevSlide,
            swiperNextSlide,
            onSwiper,

        };
    },
};
</script>
