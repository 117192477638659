<template>
    <LoaderComponent v-if="loading"></LoaderComponent>

    <div
        class="w-full p-4 text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <h1 class="font-bold text-2xl">Account ID: {{ profile.id }}</h1> <br>
        <h5 class="mb-2 text-3xl font-bold text-gray-900 dark:text-white">Welcome, {{ profile.name }}</h5>
        <h1 class="font-bold text-2xl"><span class="text-[#EDAC24]">{{ profile.email }}</span></h1> <br>

        <p class="mt-5 text-base text-gray-500 sm:text-lg dark:text-gray-400">Sending packages worldwide has never been
            easier! With Self Ship Label, you're in control.</p>
    </div>
</template>

<script>
import LoaderComponent from "../Partials/LoaderComponent.vue";

export default {
    name: "AccountDashboard",
    data() {
        return {
            profile: "",
            loading: false,
        };
    },
    components: { LoaderComponent },
    methods: {
        fetchProfile() {
            this.loading = true;
            const url = "profile";
            this.$axios
                .post(url, null, {
                    headers: this.$headers,
                })
                .then((response) => {
                    console.log(response);
                    this.profile = response.data.data.user;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.fetchProfile();
    }
};
</script>