<template>
    <div class="h-full py-4">
        <div class="border-b-2 block md:flex">
            <div class="flex flex-col w-full md:w-2/5 sm:p-2 lg:p-8 bg-white">
                <span class="text-xl font-semibold py-4 block">Edit Profile</span>
                <span class="text-gray-600">You can add and edit your personal details to make sending a parcel.</span>
                <div class="sm:order-none order-first w-full py-8 flex sm:justify-start justify-center">
                    <img id="showImage" class="h-40 w-40 items-center border rounded-full"
                        src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=200"
                        alt="">
                </div>
            </div>

            <div class="w-full md:w-3/5 sm:p-8 bg-white lg:ml-4">
                <div class="sm:p-6">
                    <div class="pb-6">
                        <label for="username" class="font-semibold text-gray-700 block pb-1">Name</label>
                        <div class="flex">
                            <input id="username" class="border-[1px] border-[#D4D4D4] rounded px-4 py-2 w-full" type="text"
                                value="" />
                        </div>
                    </div>
                    <div class="pb-4">
                        <label for="phone" class="font-semibold text-gray-700 block pb-1">Phone</label>
                        <input id="phone" class="border-[1px] border-[#D4D4D4] rounded px-4 py-2 w-full" type="number"
                            value="" />
                        <span class="text-gray-600 pt-4 block opacity-70">Personal login information of your
                            account</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AccountProfile",
    data() {
        return {
            //
        };
    },
};
</script>