import { createRouter, createWebHistory } from "vue-router";

import LoginComponent from './components/LoginComponent.vue'
import SignupComponent from './components/SignupComponent.vue'
import AboutPageComponent from './components/AboutPageComponent.vue'
import ContactComponent from './components/ContactComponent.vue'
import FAQComponent from './components/FAQComponent.vue'
import ShippingCalculatorComponent from './components/ShippingCalculatorComponent.vue'
import ShippingRestrictionsComponent from './components/ShippingRestrictionsComponent.vue'
import TermsPageComponent from './components/TermsPageComponent.vue'
import BlogsListingComponent from './components/BlogsListingComponent.vue'
import BlogDetailComponent from './components/BlogDetailComponent.vue'
import AddressComponent from './components/AddressComponent.vue'
// import CustomDeclaration from './components/Package/CustomDeclaration.vue'
import ShipmentComponent from './components/Package/ShipmentComponent.vue'
import PaymentComponent from './components/PaymentComponent.vue'
import AccountComponent from './components/Account/AccountComponent.vue'
import AccountInqueryChat from "./components/Account/AccountInqueryChat.vue";

// import IndexComponent from './components/IndexComponent.vue'
import HomePageComponent from "./components/HomePageComponent.vue"
import CalculatorComponent2 from "./components/CalculatorComponent2.vue"
import OrderHistory from "./components/Package/OrderHistory.vue";
import OrderDraft from "./components/Package/OrderDraft.vue";
import OrderPartial from "./components/Package/OrderPartial.vue";
import CartComponent from "./components/Package/CartComponent.vue";
// import CalculatorComponent from './components/CalculatorComponent.vue'

const routes = [
    {
        component: LoginComponent,
        name: 'login',
        path: '/login'
    },
    {
        component: SignupComponent,
        name: 'signup',
        path: '/signup'
    },
    {
        component: HomePageComponent,
        name: 'home',
        path: '/'
    },
    {
        component: CalculatorComponent2,
        name: 'calculator',
        path: '/calculator'
    },
    // {
    //     component: IndexComponent,
    //     name: 'index',
    //     path: '/'
    // },
    // {
    //     component: CalculatorComponent,
    //     name: 'calculator',
    //     path: '/calculator'
    // },
    {
        component: AboutPageComponent,
        name: 'about',
        path: '/about'
    },
    {
        component: ContactComponent,
        name: 'contact-us',
        path: '/contact-us'
    },
    {
        component: FAQComponent,
        name: 'frequently-ask-questions',
        path: '/faq'
    },
    {
        component: ShippingCalculatorComponent,
        name: 'shipping-calculator',
        path: '/shipping-calculator'
    },
    {
        component: ShippingRestrictionsComponent,
        name: 'shipping-restrictions',
        path: '/shipping-restrictions'
    },
    {
        component: TermsPageComponent,
        name: 'terms-and-conditions',
        path: '/terms-and-conditions'
    },
    {
        component: BlogsListingComponent,
        name: 'blogs-listing',
        path: '/blogs'
    },
    {
        component: BlogDetailComponent,
        name: 'blog-detail',
        path: '/blog-detail'
    },
    {
        component: AddressComponent,
        name: 'address',
        path: '/address',
        meta: { requiresAuth: true }
    },
    // {
    //     component: CustomDeclaration,
    //     name: 'customs',
    //     path: '/customs',
    //     meta: { requiresAuth: true }
    // },
    {
        component: OrderHistory,
        name: 'history',
        path: '/history',
        meta: { requiresAuth: true }
    },
    {
        component: OrderDraft,
        name: 'draft',
        path: '/draft',
        meta: { requiresAuth: true }
    },
    {
        component: OrderPartial,
        name: 'partial',
        path: '/partial',
        meta: { requiresAuth: true }
    },
    {
        component: CartComponent,
        name: 'cart',
        path: '/cart',
        meta: { requiresAuth: true }
    },
    {
        component: ShipmentComponent,
        name: 'shipment',
        path: '/shipment/:id?',
        meta: { requiresAuth: true },
    },
    {
        component: PaymentComponent,
        name: 'payment',
        path: '/payment',
        meta: { requiresAuth: true }
    },
    {
        component: AccountComponent,
        name: 'account',
        path: '/account',
        meta: { requiresAuth: true }
    },
    {
        component: AccountInqueryChat,
        name: 'AccountInqueryChat',
        path: '/Inquery/:slug',
        meta: { requiresAuth: true }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// // Add a global beforeEach guard
// router.beforeEach((to, from, next) => {
//     // Check if the user is leaving the '/about' route
//     if (from.path === '/shipment') {
//         const answer = window.confirm('Do you really want to leave the About page? You have unsaved changes!');

//         if (answer) {
//             next(); // Allow navigation
//         } else {
//             next(false); // Prevent navigation
//         }
//     } else {
//         next(); // Continue with navigation if not leaving the '/about' route
//     }
// });

export default router