<template>
    <nav class="fixed w-full z-50 bg-white shadow-md">
        <div
            class="container xl:max-w-[1280px] mx-auto py-2 xl:px-10 sm:px-5 px-3 flex justify-between items-center gap-0">

            <router-link to="/">
                <img class="h-[65px] sm:h-[72px] w-[100px] sm:w-[120px] sm:object-contain" src="../assets/logo.svg"
                    alt="">
            </router-link>


            <!-- Navigation Links -->
            <ul class="hidden lg:flex gap-4 font-medium">
                <li>
                    <router-link :to="{ name: 'home' }" :class="getActiveClass('home')">Home</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'shipment' }" :class="getActiveClass('shipment')">Ship Now</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'calculator' }" :class="getActiveClass('calculator')">Shipping
                        Rates</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'about' }" :class="getActiveClass('about')">About</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'contact-us' }"
                        :class="getActiveClass('contact-us')">Contact</router-link>
                </li>
            </ul>

            <div class="flex items-center gap-2 lg:text-base text-sm font-medium">
                <div class="hidden md:block space-x-2">
                    <template v-if="token">
                        <router-link to="/account">
                            <button
                                :class="name.length > 15 ? 'w-[260px]' : 'w-[220px]'"
                                class="border-[3px] border-linear px-2 py-2 bg-white text-[#EEB500] rounded-md hover:bg-gradient-to-r hover:from-[#FFD200] hover:to-[#CD7D00] hover:text-white hover:py-2.5 hover:shadow-md hover:rounded-sm hover:border-0 transition-all">
                                {{ name }}</button>
                        </router-link>
                    </template>
                    <template v-else>
                        <router-link to="/login"><button
                                class="border-[3px] border-linear px-12 py-2 bg-white text-[#EEB500] rounded-md hover:bg-gradient-to-r hover:from-[#FFD200] hover:to-[#CD7D00] hover:text-white hover:py-2.5 hover:shadow-md hover:rounded-sm hover:border-0 transition-all w-[170px]">Login</button></router-link>
                        <router-link to="/signup"><button
                                class="border-[3px] border-linear bg-gradient-to-r from-[#FFD200] to-[#CD7D00] px-12 py-2.5 text-white rounded-md hover:shadow-md hover:border-0 w-[170px] hover:rounded-sm">Register</button></router-link>
                    </template>
                </div>
                <div class="block space-x-2">
                    <button @click="sidebar = true"
                        class="bg-gradient-to-r from-[#FFD200] to-[#CD7D00] px-4 py-2 text-white rounded-md text-base lg:text-lg hover:shadow-md"><i
                            class="fa-solid fa-bars"></i></button>
                </div>
            </div>

            <SidebarComponent v-if="sidebar" @close-sidebar="closeSidebar">
            </SidebarComponent>
        </div>
    </nav>
</template>

<script>
import SidebarComponent from './SidebarComponent.vue';

export default {
    name: "NavbarComponent",
    computed: {
        currentRoute() {
            return this.$route.name; 
        }
    },
    data() {
        return {
            sidebar: false,
            token: null,
            name: null,
        };
    },
    components: { SidebarComponent },
    methods: {
        closeSidebar() {
            this.sidebar = false;
        },
        getActiveClass(routeName) {
            return this.currentRoute === routeName ? 'nav_item_active' : '';
        }
    },
    mounted() {
        this.token = localStorage.getItem('token');
        this.name = localStorage.getItem('name');
    }
};
</script>
