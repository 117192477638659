<template>
   <div class="object-cover w-full bg-center bg-cover h-[25rem] "
        style="background-image: url(https://i.postimg.cc/P5gCz1Wc/pexels-marc-mueller-380769.jpg);">
        <div class="flex flex-col items-center justify-center w-full h-full text-white bg-[linear-gradient(rgba(237,172,36,0.4),rgba(0,0,0,0.4))] ">
            <div class="text-center">
                <h1 class="text-4xl font-semibold uppercase lg:text-5xl">Terms & Conditions </h1>
            </div>
            <div class="flex gap-2 text-sm lg:text-base">
                <router-link to="/" ><button class="hover:text-[#EDAC24]">Home</button></router-link>
                    /
                <button>Terms & Conditions</button>
            </div>
        </div>
    </div>
    <div class="container mx-auto relative isolate overflow-hidden bg-white py-24 sm:py-32 sm:px-20 px-3 lg:overflow-visible">
      
      <div class="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div>
            <div class="">
              <div class="lg:pr-4">
                <div class="lg:max-w-lg">
                  <p class="text-base font-semibold leading-7 text-[#F1C21D]">Deliver faster</p>
                  <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Terms and Conditions</h1>
                  <p class="mt-6 text-base leading-8 text-gray-700">Sending packages worldwide has never been easier! With Self Ship Label, you're in control. Here are our Terms & Conditions.</p>
                </div>
              </div>
            </div>

            <div class="">
              <div class="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                <!-- <p>Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id.</p> -->
                <ul role="list" class="mt-4 space-y-8 text-gray-600">
                  <li class="flex gap-x-3">
                    <!-- <CloudArrowUpIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Self Ship Label Trademarks:</strong> Self Ship Label trademarks are exclusively reserved for products and services provided by Self Ship Label. Unauthorized use is prohibited.</span>
                  </li>
                  <li class="flex gap-x-3">
                    <!-- <LockClosedIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Copyright and Reservations:</strong> All content, designs, and software of Self Ship Label are copyrighted. Self Ship Label reserves the right to refuse service to anyone.</span>
                  </li>
                  <li class="flex gap-x-3">
                    <!-- <ServerIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Pricing and Accounts:</strong> Pricing structures and shipping prices are exclusive to Self Ship Label customers.
                      Accounts created by competitors or their affiliates may be closed, blocked, or shut down.
                      Fraud protection is a top priority, and any fraudulent activity will be reported to law enforcement agencies.
                      </span>
                  </li>
                  <li class="flex gap-x-3">
                    <!-- <ServerIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Illegal Activities:</strong> Self Ship Label customers agree not to use the service for any illegal activities or unlawful purposes.</span>
                  </li>
                  <li class="flex gap-x-3">
                    <!-- <ServerIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Shipping Rates and Changes:</strong> All shipping rates and services are subject to change.
                        Estimates are based on shipping partners (FedEx, UPS, DHL, etc.).
                      </span>
                  </li>
                </ul>

              </div>
            </div>
            
        </div>
        <div>
          <img class="w-[44rem] shadow-xl rounded-3xl" src="../assets/images/terms_page.png" alt="terms">
        </div>
      </div>
      <div>
        <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Users Terms & Conditions</h2>
                <ul role="list" class="mt-8 space-y-8 text-gray-600">
                  <li class="flex gap-x-3">
                    <!-- <CloudArrowUpIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">User Requirements:</strong> Users must provide legal name, address, payment information, and be over 18 years of age.
                        The service must not be used for illegal activities or violations of any laws.
                        Only payments authorized by the user's financial account are accepted.
                      </span>
                  </li>
                  <li class="flex gap-x-3">
                    <!-- <CloudArrowUpIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Storage and Shipment:</strong> Packages can be kept for an additional 80 days after free storage expires.
                          Permanently removed packages may be disposed of or sold to cover outstanding storage fees.
                      </span>
                  </li>
                  <li class="flex gap-x-3">
                    <!-- <CloudArrowUpIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Shipping Regulations:</strong> All items for import and export must comply with carrier rules, US Customs & Border Protection, US Department of Commerce, and Department of Homeland Security regulations.
                        Self Ship Label reserves the right to close or suspend accounts due to fraudulent or illegal activity.
                      </span>
                  </li>
                </ul>

                <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Import & Export Terms & Conditions</h2>
                <ul role="list" class="mt-8 space-y-8 text-gray-600">
                  <li class="flex gap-x-3">
                    <!-- <CloudArrowUpIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Compliance Requirements:</strong> Items being exported or imported must comply with US Department of Commerce, US Department of Homeland Security, and US Customs Border Protection regulations.
                          Items must also adhere to shipping carriers' regulations.
                      </span>
                  </li>
                  <li class="flex gap-x-3">
                    <!-- <CloudArrowUpIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Duties and Taxes:</strong> Self Ship Label is not responsible for duties or taxes on exported packages.
                          Packages are sent as Delivery Duty Unpaid.
                      </span>
                  </li>
                  <li class="flex gap-x-3">
                    <!-- <CloudArrowUpIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Inspection and EEI:</strong> All packages are subject to inspection prior to export.
                          Items valued over $2500 require Electronic Export Information (EEI) for FedEx and DHL shipments.
                      </span>
                  </li>
                </ul>

                <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Prohibited / Restricted Items Terms & Conditions</h2>
                <ul role="list" class="mt-8 space-y-8 text-gray-600">
                  <li class="flex gap-x-3">
                    <!-- <CloudArrowUpIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Items Not Allowed:</strong> Prohibited items include combustible, flammable items, pressurized cans, hazardous materials, firearms, ammunition, agriculture and produce, monetary items, drugs, marijuana products, indecent items, stand-alone lithium-ion batteries, tobacco products, and certain restricted items.
                      </span>
                  </li>
                  <li class="flex gap-x-3">
                    <!-- <CloudArrowUpIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Prohibited Countries:</strong> Self Ship Label cannot provide service to North Korea, Iran, Cuba, Myanmar, Syria, Sudan, Crimea, and any other countries listed as prohibited or embargoed.
                      </span>
                  </li>
                
                </ul>

                <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Privacy Terms & Condition</h2>
                <ul role="list" class="mt-8 space-y-8 text-gray-600">
                  <li class="flex gap-x-3">
                    <!-- <CloudArrowUpIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Personal Information Protection:</strong> Personal information provided to Self Ship Label will not be shared with third parties.
                          Self Ship Label may provide contact information to carriers for package delivery purposes only.
                      </span>
                  </li>
                  <li class="flex gap-x-3">
                    <!-- <CloudArrowUpIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Account Usage:</strong> Sharing or selling account information is prohibited.
                          Customers can request a report of stored data.
                      </span>
                  </li>
                
                </ul>

                <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Insurance & Claim Term & Condition</h2>
                <ul role="list" class="mt-8 space-y-8 text-gray-600">
                  <li class="flex gap-x-3">
                    <!-- <CloudArrowUpIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Insurance Guidelines:</strong> The insurance value of a package cannot exceed the actual item value.
                          Insurance claims require a retail receipt/invoice for lost, damaged, or missing items.
                      </span>
                  </li>
                  <li class="flex gap-x-3">
                    <!-- <CloudArrowUpIcon class="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> -->
                    <span><strong class="font-semibold text-gray-900">Claim Process:</strong> Claims for packages not entered or lost/damaged during shipping must be filed within specific timelines.
                        Different carriers have specific timelines and processes for claims.
                        These terms and conditions are designed to ensure a transparent, secure, and efficient experience for all Self Ship Label users. If you have further questions, please feel free to contact our customer support team.</span>
                  </li>
                
                </ul>
      </div>
    </div>
  </template>
  