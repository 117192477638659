<template>
      <section class="pb-11">
        <div class="object-cover w-full bg-center bg-cover h-[25rem] "
        style="background-image: url(https://i.postimg.cc/P5gCz1Wc/pexels-marc-mueller-380769.jpg);">
        <div class="flex flex-col items-center justify-center w-full h-full text-white bg-[linear-gradient(rgba(237,172,36,0.4),rgba(0,0,0,0.4))] ">
            <div class="text-center">
                <h1 class="text-4xl font-semibold uppercase lg:text-5xl">Blog detail</h1>
            </div>
            <div class="flex gap-2 text-sm lg:text-base">
                <router-link to="/" ><button class="hover:text-[#EDAC24]">Home</button></router-link>
                    /
                <button>Blog detail</button>
            </div>
        </div>
    </div>
        <div class="max-w-6xl mx-auto">
            <div class="grid lg:grid-cols-[70%,1fr]  gap-4  pt-9 pb-7">
                <div>
                    <div class="p-4 ">
                      
                        <img src="https://i.postimg.cc/vHR493zf/pexels-pixabay-269077.jpg" alt=""
                            class="object-cover w-full rounded-md h-96">
                        <div class="flex mt-6 mb-4 ">
                            <a href="#" class="flex items-center mr-6 no-underline ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="text-[#EDAC24]  bi bi-person-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                </svg>
                                <span class="ml-2 text-sm hover:text-[#EDAC24] ">Adam Smith</span>
                            </a>
                            <a href="#" class="flex items-center text-black no-underline ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="text-[#EDAC24]  bi bi-chat" viewBox="0 0 16 16">
                                    <path
                                        d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z">
                                    </path>
                                </svg>
                                <span class="ml-2 text-sm hover:text-[#EDAC24]  ">comments</span>
                            </a>
                        </div>
                        <h2 class="mb-4 text-2xl font-semibold font-poppins ">
                            Easy and Most Powerful Server and Platform.</h2>
                        <div class=" font-poppins ">
                            <p> Lorem ipsum dolor sit amet, labore et dolore magna aliqua. Ut enim ad minim veniam
                                consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut
                                labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                            <blockquote
                                class="p-6 my-4 italic bg-gray-100 border-l-4 border-[#EDAC24]  ">
                                Lorem
                                ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu nunc commodo
                                posuere
                                et
                                sit
                                amet ligula.</blockquote>
                            <p class="pb-4"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor
                                incididunt ut
                                labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet,
                                consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut
                                labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut
                                labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                        </div>
                    </div>
                    <div class="px-4">
                        <h2 class="pb-2 mt-4 text-lg font-semibold text-gray-900  font-poppins">Tags
                        </h2>
                        <div class="w-16 mb-3 border-b-2 border-[#EDAC24] inset-px"></div>
                        <div class="flex flex-wrap gap-2 my-4 font-poppins ">
                            <a class="px-4 py-1 mb-2 text-xs text-black transition bg-gray-200 rounded-md btn btn-sm hover:bg-[#EDAC24]  hover:text-white"
                                href="#">
                                Corporate</a>
                            <a class="px-4 py-1 mb-2 text-xs text-black transition bg-gray-200 rounded-md btn btn-sm hover:bg-[#EDAC24] hover:text-white "
                                href="#">
                                Business</a>
                            <a class="px-4 py-1 mb-2 text-xs text-black transition bg-gray-200 rounded-md btn btn-sm hover:bg-[#EDAC24] hover:text-white "
                                href="#">
                                Field</a>
                        </div>
                    </div>
                </div>
                <div class="px-4 lg:px-0">
                    <div class="px-2 pt-4 lg:px-0 ">
                        <div>
                            <h2
                                class="pb-2 text-lg font-semibold leading-5 tracking-tight text-gray-900  ">
                                Recent Posts</h2>
                            <div class="w-16 mb-5 border-b-2 border-[#EDAC24] inset-px "></div>
                            <div class="flex w-full mb-4 border-b border-gray-200 ">
                                <div>
                                    <img class="object-cover w-20 h-20 mr-4 rounded"
                                        src="https://i.postimg.cc/SKtsKrRX/pexels-marc-mueller-380769.jpg" alt="">
                                </div>
                                <div class="flex-1 mb-5">
                                    <h2
                                        class="mb-1 text-base font-medium tracking-tight text-gray-700 hover:text-[#EDAC24] ">
                                        <a href="#">
                                            Lorem ipsum dolor sit amet, labore et dolore</a>
                                    </h2>
                                    <a href="#" class="flex items-center mr-6 no-underline ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor"
                                            class="w-3 h-3 text-[#EDAC24]  bi bi-calendar"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                        </svg>
                                        <span
                                            class="ml-2 text-xs text-gray-500  hover:text-[#EDAC24]">May
                                            10, 2022</span>
                                    </a>
                                </div>
                            </div>
                            <div class="flex w-full mb-4 border-b border-gray-200 ">
                                <div>
                                    <img class="object-cover w-20 h-20 mr-4 rounded"
                                        src="https://i.postimg.cc/63GLBzwc/aqq.jpg" alt="">
                                </div>
                                <div class="flex-1 mb-5">
                                    <h2
                                        class="mb-1 text-base font-medium tracking-tight text-gray-700 hover:text-[#EDAC24] ">
                                        <a href="#">
                                            Lorem ipsum dolor sit amet, labore et dolore</a>
                                    </h2>
                                    <a href="#" class="flex items-center mr-6 no-underline ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor"
                                            class="w-3 h-3 text-[#EDAC24]  bi bi-calendar"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                        </svg>
                                        <span
                                            class="ml-2 text-xs text-gray-500 hover:text-[#EDAC24]  ">
                                            May 10, 2022</span>
                                    </a>
                                </div>
                            </div>
                            <div class="flex w-full mb-4 ">
                                <div>
                                    <img class="object-cover w-20 h-20 mr-4 rounded"
                                        src="https://i.postimg.cc/PqC1MKLH/pexels-pixabay-38271.jpg" alt="">
                                </div>
                                <div class="flex-1 mb-5">
                                    <h2
                                        class="mb-1 text-base font-medium tracking-tight text-gray-700 hover:text-[#EDAC24] ">
                                        <a href="#">
                                            Lorem ipsum dolor sit amet, labore et dolore</a>
                                    </h2>
                                    <a href="#" class="flex items-center mr-6 no-underline">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor"
                                            class="w-3 h-3 text-[#EDAC24]  bi bi-calendar"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                        </svg>
                                        <span
                                            class="ml-2 text-xs text-gray-500 hover:text-[#EDAC24]  ">May
                                            10, 2022</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-4 mb-4 border border-gray-200 rounded-md  ">
                        <h2
                            class="pb-2 text-lg font-semibold leading-5 tracking-tight text-gray-900  ">
                            Categories</h2>
                        <div class="w-16 mb-5 border-b-2 border-[#EDAC24] inset-px "></div>
                        <ul>
                            <li class="mb-4">
                                <a href="#"
                                    class="flex items-center mr-4 no-underline  hover:text-[#EDAC24] ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="w-3 h-3 bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                    <span
                                        class="ml-2 text-sm text-gray-500 hover:text-[#EDAC24]  ">Technology</span>
                                    <span
                                        class="inline-flex items-center justify-center w-4 h-4 ml-auto text-xs font-medium text-white bg-[#EDAC24] rounded-full  ">
                                        3
                                    </span>
                                </a>
                            </li>
                            <li class="mb-4">
                                <a href="#"
                                    class="flex items-center mr-4 no-underline  hover:text-[#EDAC24] ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="w-3 h-3 bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                    <span
                                        class="ml-2 text-sm text-gray-500 hover:text-[#EDAC24]  ">Innovation</span>
                                    <span
                                        class="inline-flex items-center justify-center w-4 h-4 ml-auto text-xs font-medium text-white bg-[#EDAC24] rounded-full  ">
                                        4
                                    </span>
                                </a>
                            </li>
                            <li class="mb-4">
                                <a href="#"
                                    class="flex items-center mr-4 no-underline   hover:text-[#EDAC24]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="w-3 h-3 bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                    <span
                                        class="ml-2 text-sm text-gray-500   hover:text-[#EDAC24]">Information</span>
                                    <span
                                        class="inline-flex items-center justify-center w-4 h-4 ml-auto text-xs font-medium text-white bg-[#EDAC24] rounded-full  ">
                                        6
                                    </span>
                                </a>
                            </li>
                            <li class="mb-4">
                                <a href="#"
                                    class="flex items-center mr-4 no-underline   hover:text-[#EDAC24]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="w-3 h-3 bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                    <span
                                        class="ml-2 text-sm text-gray-500   hover:text-[#EDAC24]">Learning</span>
                                    <span
                                        class="inline-flex items-center justify-center w-4 h-4 ml-auto text-xs font-medium text-white bg-[#EDAC24] rounded-full  ">
                                        2
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="p-4 mb-4 bg-white border rounded-md shadow  ">
                        <h2
                            class="py-2 my-2 text-xl font-semibold leading-5 tracking-tight text-gray-900 font-montserrat lg:text-2xl  lg:mt-3 ">
                            Join Our Newsletter</h2>
                        <input type="email " name="email " id="email " autocomplete="email"
                            placeholder="Drop your email"
                            class="w-full px-2 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-[#EDAC24] focus-ring-2 focus:ring-[#EDAC24] ">
                        <button
                            class="flex justify-center w-full px-4 py-2 mt-4 text-sm font-medium text-gray-100 uppercase bg-[#EDAC24] border border-transparent rounded-md shadow-sm hover:bg-[#e8a411] focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-[#EDAC24] ">
                            Subscribe
                        </button>
                        <p class="mt-2 text-sm text-gray-500 ">
                            Join our email list to get new updates
                            and
                            inspirations
                            for
                            free.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>