<template>
    <section class="h-full">

        <div class="space-y-3 xl:space-y-4 overflow-x-hidden" v-if="rates.length > 0 && !loading">
            <div>
                <h6
                    class="bg-gradient-to-br from-[#FFD200] to-[#CD7D00] text-white text-lg lg:text-xl xl:text-[22px] font-bold text-center rounded-t-xl shadow-[inset_0_4px_10px_0px_rgba(0,0,0,0.1)] py-4">
                    {{ rates.length }} Results Found</h6>
            </div>
            <div class="py-3 px-3 xl:px-5 xl:py-5 rounded-xl shadow-[inset_0_0_2px_0.35px_rgba(0,0,0,0.1)]">
                <div class="grid grid-cols-1 gap-4">
                </div>

                <div class="py-0">
                    <label class="block mb-2 pl-1 sm:pl-0 font-medium text-[#2E2E2E]">Packages</label>
                    <div class="py-2 pr-2 min-h-[380px] xl:min-h-[530px] overflow-y-auto space-y-3">
                        <template v-for="rate in rates" :key="rate.id">
                            <div
                                class="relative grid grid-cols-[72px,1fr] sm:grid-cols-[100px,1fr,130px] xl:grid-cols-[82px,1fr,140px] items-start sm:items-center gap-1 sm:gap-2 xl:gap-2 rounded-md p-2 xl:p-4 bg-white border hover:bg-gradient-to-br from-[#FFD200] to-[#CD7D00] group transition-all">
                                <img v-if="rate.code == 'fedex'"
                                    class="w-[68px] h-[62px] sm:w-[80px] sm:h-[72px] lg:w-[79px] lg:h-[72px] object-cover rounded-md"
                                    src='../assets/images/calc/fedex.svg' alt="">
                                <img v-if="rate.code == 'ups'"
                                    class="w-[68px] h-[62px] sm:w-[80px] sm:h-[72px] lg:w-[79px] lg:h-[72px] object-cover rounded-md"
                                    src='../assets/images/calc/ups-logo.svg' alt="">
                                <img v-if="rate.code == 'dhl'"
                                    class="w-[68px] h-[62px] sm:w-[80px] sm:h-[72px] lg:w-[79px] lg:h-[72px] object-cover rounded-md"
                                    src='../assets/images/calc/dhl-logo.svg' alt="">
                                <img v-if="rate.code == 'usps'"
                                    class="w-[68px] h-[62px] sm:w-[80px] sm:h-[72px] lg:w-[79px] lg:h-[72px] object-cover rounded-md"
                                    src='../assets/images/calc/usps-logo.svg' alt="">
                                <div class="text-[#676767]">
                                    <h6
                                        class="group-hover:text-white font-bold text-sm sm:text-base xl:text-sm text-[#2E2E2E]">
                                        {{ rate.name }}
                                    </h6>
                                    <p class="group-hover:text-white text-xs mb-4">More about {{rate.code}} Shipping
                                    </p>
                                    <p class="hidden sm:block text-[#2E7E31] text-xs">
                                        <!-- Delivered by August 14, 2024 | 12:00 PM -->
                                    </p>
                                </div>
                                <div class="col-span-2 block sm:hidden">
                                    <p class="text-[#2E7E31] text-xs">
                                        <!-- Delivered by August 14, 2024 | 12:00 PM -->
                                    </p>
                                </div>
                                <div
                                    class="flex flex-col justify-between items-end text-[#676767] h-full gap-2 sm:gap-0 col-span-2 sm:col-span-1">
                                    <div class="flex justify-between items-center sm:block sm:w-auto w-full">
                                        <div
                                            class="bg-white rounded-sm sm:absolute top-1 right-28 lg:right-28 py-0.5 px-1.5 text-xs">
                                            <span class="text-linear">40% Off</span>
                                        </div>
                                        <p class="group-hover:text-white font-normal text-xl text-[#2E2E2E]">
                                            <span>${{ rate.total }}</span>
                                        </p>
                                    </div>
                                    <router-link to="/shipment" class="w-full sm:w-auto">
                                        <button
                                            class="w-full sm:w-auto px-6 xl:px-6 py-1 xl:py-1 border-2 border-linear text-[#EEB500] rounded-md group-hover:border-white hover:bg-white hover:shadow-md transition-all">Ship
                                            now</button>
                                    </router-link>
                                </div>
                            </div>
                        </template>


                    </div>
                </div>
            </div>
        </div>

        <div v-if="loading"
            class="py-3 px-3 xl:px-5 xl:py-5 rounded-xl shadow-[inset_0_0_2px_0.35px_rgba(0,0,0,0.1)] overflow-hidden bg-[rgba(255,255,255,0.976)] items-center flex flex-col gap-4 justify-center h-full w-full">
            <div class="loader w-24 h-24 lg:w-36 lg:h-36 border-[10px] border-t-[10px] lg:border-t-[12px] lg:border-[12px]">
            </div>
            <b class="text-sm text-center">Please wait we are fetching best rates for you.</b>
        </div>

        <template v-if="rates.length == 0 && !loading">
            <div
                class="h-full flex flex-col gap-3 py-10 lg:py-0 lg:pt-60 items-center rounded-xl shadow-[inset_0_0_2px_0.35px_rgba(0,0,0,0.1)]">
                <img class="h-28 w-36 3xl:h-32 3xl:w-40 object-contain" src="../assets/images/no-results.png" alt="logo">
                <b class="text-center text-sm text-black">
                    No rates were found. Please try a different location or
                    dimensions
                </b>
            </div>
        </template>

    </section>
</template>

<script>
// import LoaderComponent from './Partials/LoaderComponent.vue';
export default {
    name: "ResultComponent",
    data() {
        return {
            errors: {},
            form1: this.form,
            set_rate_loading: false,
        };
    },
    props: {
        rates: Array,
        form: Object,
        loading: Boolean,
    },
    methods: {
        setRate(rate) {
            localStorage.setItem('selected_rate', JSON.stringify(rate));
            this.form1.rate = rate;
            this.set_rate_loading = true;
            this.errors = {};
            this.$axios
                .post("package/set-rate", this.form1, {
                    headers: this.$headers
                })
                .then((response) => {
                    console.log(response)
                    // this.$router.push('address');
                })
                .catch((error) => {
                    this.errors = error.response;
                })
                .finally(() => {
                    this.set_rate_loading = false;
                });
        },
    },

};
</script>