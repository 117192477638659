<template>

    <section class="bg-white">
        <div class="object-cover w-full bg-center bg-cover h-[25rem] "
        style="background-image: url(https://i.postimg.cc/P5gCz1Wc/pexels-marc-mueller-380769.jpg);">
        <div class="flex flex-col items-center justify-center w-full h-full text-white bg-[linear-gradient(rgba(237,172,36,0.4),rgba(0,0,0,0.4))] ">
            <div class="text-center">
                <h1 class="text-4xl font-semibold uppercase lg:text-5xl">Blogs </h1>
            </div>
            <div class="flex gap-2 text-sm lg:text-base">
                <router-link to="/" ><button class="hover:text-[#EDAC24]">Home</button></router-link>
                    /
                <button>Blogs</button>
            </div>
        </div>
    </div>
      <div class="container mx-auto py-8 sm:py-16 sm:px-20 px-3">

        <div class="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 py-10 lg:mx-0 lg:max-w-none sm:py-10 lg:grid-cols-3">
            <article v-for="post in posts" :key="post.id" class="flex max-w-xl flex-col gap-5 items-start justify-between">
                <div class="overflow-hidden group duration-700 transition-all hover:outline outline-8 outline-[#EDAC24] rounded-3xl">
                    <img class="group-hover:scale-125 duration-700 transition-all h-72 w-full rounded-3xl" :src="post.img" alt="img">
                </div>
                <div>
                    <div class="flex items-center gap-x-4 text-xs">
                        <time :datetime="post.datetime" class="text-gray-500">{{ post.date }}</time>
                        <a :href="post.category.href" class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">{{ post.category.title }}</a>
                    </div>
                    <div class="group relative">
                        <h3 class="mt-3 text-lg font-semibold leading-6 text-[#EDAC24] group-hover:text-[#ffd77f]">
                        <a :href="post.href">
                            <span class="absolute inset-0" />
                            {{ post.title }}
                        </a>
                        </h3>
                        <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{{ post.description }}</p>
                    </div>
                    <div class="relative mt-8 flex items-center gap-x-4">
                        <img :src="post.author.imageUrl" alt="" class="h-10 w-10 rounded-full bg-gray-50" />
                        <div class="text-sm leading-6">
                        <p class="font-semibold text-gray-900">
                            <a :href="post.author.href">
                            <span class="absolute inset-0" />
                            {{ post.author.name }}
                            </a>
                        </p>
                        <p class="text-gray-600">{{ post.author.role }}</p>
                        </div>
                    </div>
                </div>
            </article>
        </div>

      </div>
      
    </section>
</template>

<script setup>
    const posts = [
    {
        id: 1,
        img: "https://img.freepik.com/free-photo/glowing-spaceship-orbits-planet-starry-galaxy-generated-by-ai_188544-9655.jpg?size=626&ext=jpg&ga=GA1.1.1448711260.1706313600&semt=sph",
        title: 'Boost your conversion rate',
        href: '#',
        description:
        'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '#' },
        author: {
            name: 'Michael Foster',
            role: 'Co-Founder / CTO',
            href: '#',
            imageUrl: 'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    {
        id: 2,
        img: "https://img.freepik.com/free-photo/glowing-spaceship-orbits-planet-starry-galaxy-generated-by-ai_188544-9655.jpg?size=626&ext=jpg&ga=GA1.1.1448711260.1706313600&semt=sph",
        title: 'Boost your conversion rate',
        href: '#',
        description:
        'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '#' },
        author: {
        name: 'Michael Foster',
        role: 'Co-Founder / CTO',
        href: '#',
        imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    {
        id: 3,
        img: "https://img.freepik.com/free-photo/glowing-spaceship-orbits-planet-starry-galaxy-generated-by-ai_188544-9655.jpg?size=626&ext=jpg&ga=GA1.1.1448711260.1706313600&semt=sph",
        title: 'Boost your conversion rate',
        href: '#',
        description:
        'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '#' },
        author: {
        name: 'Michael Foster',
        role: 'Co-Founder / CTO',
        href: '#',
        imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    // More posts...
    ]
</script>