import { createApp } from 'vue';
import App from './App.vue';
import router from "./routers";
import './axios';
import './index.css';
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';

library.add(faUserSecret, faUser)

const vue = createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router);

// check if token is expired on each request
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = localStorage.getItem('token') // check if user is authenticated (example uses token stored in local storage)

  if (requiresAuth && !isAuthenticated) {
    next('/login')
  } else {
    vue.config.globalProperties.$axios = axios;
    vue.config.globalProperties.$headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    next()
  }
})


// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBsyK46v5kyLJWy4Cd6aQiCxKKQ_4ybt8A",
  authDomain: "self-ship-label.firebaseapp.com",
  projectId: "self-ship-label",
  storageBucket: "self-ship-label.appspot.com",
  messagingSenderId: "742964714477",
  appId: "1:742964714477:web:8a80a1774a5cd5364525e4",
  measurementId: "G-ZVWJM4FHKC"
};
// Initialize Firebase


let firebaseApp;

if (!getApps.length) {
  firebaseApp = initializeApp(firebaseConfig)
}
const db = getFirestore(firebaseApp);
console.log(db);
export { db };



// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     authEndpoint:'http://127.0.0.1:8000/api/broadcasting/auth',
//     key: process.env.VUE_APP_PUSHER_APP_KEY,
//     cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
//     wsHost: window.location.hostname,
//     wsPort: 6001,
//     forceTLS: false,
//     encrypted: false,
//     auth: {
//       headers: {
//           Authorization: 'Bearer ' + localStorage.getItem("token"),
//       },
//   },
// });

vue.mount('#app');