<template>
    <section>
        <div class="object-cover w-full bg-center bg-cover h-[25rem] "
            style="background-image: url(https://i.postimg.cc/P5gCz1Wc/pexels-marc-mueller-380769.jpg);">
            <div class="flex flex-col items-center justify-center w-full h-full text-white bg-[linear-gradient(rgba(237,172,36,0.4),rgba(0,0,0,0.4))] ">
                <div class="text-center">
                    <h1 class="text-4xl font-semibold uppercase lg:text-5xl">Shipping Restrictions</h1>
                </div>
                <div class="flex gap-2 text-sm lg:text-base">
                    <router-link to="/" ><button class="hover:text-[#EDAC24]">Home</button></router-link>
                        /
                    <button>Shipping Restrictions</button>
                </div>
            </div>
        </div>
        <div class="container mx-auto relative isolate overflow-hidden bg-white pt-12 pb-24 sm:pb-28 sm:px-20 px-3 lg:overflow-visible">
            <div>
                <h2 class="text-base font-semibold leading-7 text-[#EDAC24]">Deliver faster</h2>
                <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Prohibited Items: What's on the No-Fly List</p>
                <p class="mt-4 text-base leading-8 text-gray-600">Your Shipments Are in Safe Hands with Self Ship Label Service.</p>
                <p class="text-base leading-8 text-gray-600">To guarantee a flawless shipping experience for our clients, we place a high priority on safety, security, and compliance. In order to uphold the highest standards in shipping services, we have compiled an extensive list of things that are not allowed. Our clients must be aware of these limitations to guarantee the security of their cargo and adherence to global shipping laws.</p>
            </div>

            <div class="grid gap-5 md:grid-cols-2 xl:grid-cols-3 py-10">
                <div class="w-full">
                    <div  class="h-full border p-6 text-center transition duration-200 rounded-md shadow bg-gray-50 hover:bg-white  hover:shadow-xl">
                        <div
                            class="inline-flex items-center justify-center w-16 h-16 mx-auto mb-6 bg-[#EDAC24] rounded-lg  text-gray-50">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-sign-stop" viewBox="0 0 16 16">
                                <path d="M3.16 10.08c-.931 0-1.447-.493-1.494-1.132h.653c.065.346.396.583.891.583.524 0 .83-.246.83-.62 0-.303-.203-.467-.637-.572l-.656-.164c-.61-.147-.978-.51-.978-1.078 0-.706.597-1.184 1.444-1.184.853 0 1.386.475 1.436 1.087h-.645c-.064-.32-.352-.542-.797-.542-.472 0-.77.246-.77.6 0 .261.196.437.553.522l.654.161c.673.164 1.06.487 1.06 1.11 0 .736-.574 1.228-1.544 1.228Zm3.427-3.51V10h-.665V6.57H4.753V6h3.006v.568H6.587Z"/>
                                <path fill-rule="evenodd" d="M11.045 7.73v.544c0 1.131-.636 1.805-1.661 1.805-1.026 0-1.664-.674-1.664-1.805V7.73c0-1.136.638-1.807 1.664-1.807s1.66.674 1.66 1.807Zm-.674.547v-.553c0-.827-.422-1.234-.987-1.234-.572 0-.99.407-.99 1.234v.553c0 .83.418 1.237.99 1.237.565 0 .987-.408.987-1.237m1.15-2.276h1.535c.82 0 1.316.55 1.316 1.292 0 .747-.501 1.289-1.321 1.289h-.865V10h-.665zm1.436 2.036c.463 0 .735-.272.735-.744s-.272-.741-.735-.741h-.774v1.485z"/>
                                <path fill-rule="evenodd" d="M4.893 0a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146A.5.5 0 0 0 11.107 0zM1 5.1 5.1 1h5.8L15 5.1v5.8L10.9 15H5.1L1 10.9z"/>
                                </svg>
                        </div>
                        <h2 class="mb-4 text-xl font-bold leading-tight md:text-2xl">Good-bye, hazardous materials</h2>
                        <p class="font-medium text-gray-600 ">We apologise, but explosives, flammable gases, and dangerous materials are not welcome at our shipping party. Since safety is our first priority, make sure you are informed about current international rules regarding potentially dangerous goods.</p>
                    </div>
                </div>
                <div class="w-full">
                    <div
                        class="border h-full p-6 text-center transition duration-200 rounded-md shadow bg-gray-50 hover:bg-white  hover:shadow-xl">
                        <div
                            class="inline-flex items-center justify-center w-16 h-16 mx-auto mb-6 bg-[#EDAC24] rounded-lg text-gray-50 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-sign-stop" viewBox="0 0 16 16">
                                <path d="M3.16 10.08c-.931 0-1.447-.493-1.494-1.132h.653c.065.346.396.583.891.583.524 0 .83-.246.83-.62 0-.303-.203-.467-.637-.572l-.656-.164c-.61-.147-.978-.51-.978-1.078 0-.706.597-1.184 1.444-1.184.853 0 1.386.475 1.436 1.087h-.645c-.064-.32-.352-.542-.797-.542-.472 0-.77.246-.77.6 0 .261.196.437.553.522l.654.161c.673.164 1.06.487 1.06 1.11 0 .736-.574 1.228-1.544 1.228Zm3.427-3.51V10h-.665V6.57H4.753V6h3.006v.568H6.587Z"/>
                                <path fill-rule="evenodd" d="M11.045 7.73v.544c0 1.131-.636 1.805-1.661 1.805-1.026 0-1.664-.674-1.664-1.805V7.73c0-1.136.638-1.807 1.664-1.807s1.66.674 1.66 1.807Zm-.674.547v-.553c0-.827-.422-1.234-.987-1.234-.572 0-.99.407-.99 1.234v.553c0 .83.418 1.237.99 1.237.565 0 .987-.408.987-1.237m1.15-2.276h1.535c.82 0 1.316.55 1.316 1.292 0 .747-.501 1.289-1.321 1.289h-.865V10h-.665zm1.436 2.036c.463 0 .735-.272.735-.744s-.272-.741-.735-.741h-.774v1.485z"/>
                                <path fill-rule="evenodd" d="M4.893 0a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146A.5.5 0 0 0 11.107 0zM1 5.1 5.1 1h5.8L15 5.1v5.8L10.9 15H5.1L1 10.9z"/>
                                </svg>
                        </div>
                        <h2 class="mb-4 text-xl font-bold leading-tight md:text-2xl">No Room for Illegal Substances</h2>
                        <p class="font-medium text-gray-600 ">We don't tolerate the use of illegal substances at all. Prohibited substances, narcotics, and anything else that is against the law are not allowed anywhere on Self Ship Label. If you try to smuggle these in, your cargo will be quickly cancelled and legal action will be taken.</p>
                    </div>
                </div>
                <div class="w-full">
                    <div class="border h-full p-6 text-center transition duration-200 rounded-md shadow bg-gray-50  hover:bg-white hover:shadow-xl">
                        <div
                            class="inline-flex items-center justify-center w-16 h-16 mx-auto mb-6 bg-[#EDAC24] rounded-lg text-gray-50 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-sign-stop" viewBox="0 0 16 16">
                                <path d="M3.16 10.08c-.931 0-1.447-.493-1.494-1.132h.653c.065.346.396.583.891.583.524 0 .83-.246.83-.62 0-.303-.203-.467-.637-.572l-.656-.164c-.61-.147-.978-.51-.978-1.078 0-.706.597-1.184 1.444-1.184.853 0 1.386.475 1.436 1.087h-.645c-.064-.32-.352-.542-.797-.542-.472 0-.77.246-.77.6 0 .261.196.437.553.522l.654.161c.673.164 1.06.487 1.06 1.11 0 .736-.574 1.228-1.544 1.228Zm3.427-3.51V10h-.665V6.57H4.753V6h3.006v.568H6.587Z"/>
                                <path fill-rule="evenodd" d="M11.045 7.73v.544c0 1.131-.636 1.805-1.661 1.805-1.026 0-1.664-.674-1.664-1.805V7.73c0-1.136.638-1.807 1.664-1.807s1.66.674 1.66 1.807Zm-.674.547v-.553c0-.827-.422-1.234-.987-1.234-.572 0-.99.407-.99 1.234v.553c0 .83.418 1.237.99 1.237.565 0 .987-.408.987-1.237m1.15-2.276h1.535c.82 0 1.316.55 1.316 1.292 0 .747-.501 1.289-1.321 1.289h-.865V10h-.665zm1.436 2.036c.463 0 .735-.272.735-.744s-.272-.741-.735-.741h-.774v1.485z"/>
                                <path fill-rule="evenodd" d="M4.893 0a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146A.5.5 0 0 0 11.107 0zM1 5.1 5.1 1h5.8L15 5.1v5.8L10.9 15H5.1L1 10.9z"/>
                                </svg>
                        </div>
                        <h2 class="mb-4 text-xl font-bold leading-tight md:text-2xl">Weapons Remain at Home</h2>
                        <p class="font-medium text-gray-600 ">Weapons, ammo, and explosives must remain where they are. Safe transportation is our goal; we're not here to toy with fire. We won't be accepting guns or ammunition through our doors, so leave them at home.</p>
                    </div>
                </div>
                <div class="w-full">
                    <div
                        class="border h-full p-6 text-center transition duration-200 rounded-md shadow bg-gray-50  hover:bg-white hover:shadow-xl">
                        <div
                            class="inline-flex items-center justify-center w-16 h-16 mx-auto mb-6 bg-[#EDAC24] rounded-lg text-gray-50 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-sign-stop" viewBox="0 0 16 16">
                                <path d="M3.16 10.08c-.931 0-1.447-.493-1.494-1.132h.653c.065.346.396.583.891.583.524 0 .83-.246.83-.62 0-.303-.203-.467-.637-.572l-.656-.164c-.61-.147-.978-.51-.978-1.078 0-.706.597-1.184 1.444-1.184.853 0 1.386.475 1.436 1.087h-.645c-.064-.32-.352-.542-.797-.542-.472 0-.77.246-.77.6 0 .261.196.437.553.522l.654.161c.673.164 1.06.487 1.06 1.11 0 .736-.574 1.228-1.544 1.228Zm3.427-3.51V10h-.665V6.57H4.753V6h3.006v.568H6.587Z"/>
                                <path fill-rule="evenodd" d="M11.045 7.73v.544c0 1.131-.636 1.805-1.661 1.805-1.026 0-1.664-.674-1.664-1.805V7.73c0-1.136.638-1.807 1.664-1.807s1.66.674 1.66 1.807Zm-.674.547v-.553c0-.827-.422-1.234-.987-1.234-.572 0-.99.407-.99 1.234v.553c0 .83.418 1.237.99 1.237.565 0 .987-.408.987-1.237m1.15-2.276h1.535c.82 0 1.316.55 1.316 1.292 0 .747-.501 1.289-1.321 1.289h-.865V10h-.665zm1.436 2.036c.463 0 .735-.272.735-.744s-.272-.741-.735-.741h-.774v1.485z"/>
                                <path fill-rule="evenodd" d="M4.893 0a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146A.5.5 0 0 0 11.107 0zM1 5.1 5.1 1h5.8L15 5.1v5.8L10.9 15H5.1L1 10.9z"/>
                                </svg>
                        </div>
                        <h2 class="mb-4 text-xl font-bold leading-tight md:text-2xl">Keep It Fresh, But Not Too Fresh</h2>
                        <p class="font-medium text-gray-600 ">We are experts in prompt and effective shipment, but we lack the equipment necessary to handle perishable items that require precise temperature controls. For anything that has a limited shelf life, think about using a different transportation method.</p>
                    </div>
                </div>
                <div class="w-full">
                    <div
                        class="border h-full p-6 text-center transition duration-200 rounded-md shadow bg-gray-50  hover:bg-white hover:shadow-xl">
                        <div
                            class="inline-flex items-center justify-center w-16 h-16 mx-auto mb-6 bg-[#EDAC24] rounded-lg text-gray-50 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-sign-stop" viewBox="0 0 16 16">
                                <path d="M3.16 10.08c-.931 0-1.447-.493-1.494-1.132h.653c.065.346.396.583.891.583.524 0 .83-.246.83-.62 0-.303-.203-.467-.637-.572l-.656-.164c-.61-.147-.978-.51-.978-1.078 0-.706.597-1.184 1.444-1.184.853 0 1.386.475 1.436 1.087h-.645c-.064-.32-.352-.542-.797-.542-.472 0-.77.246-.77.6 0 .261.196.437.553.522l.654.161c.673.164 1.06.487 1.06 1.11 0 .736-.574 1.228-1.544 1.228Zm3.427-3.51V10h-.665V6.57H4.753V6h3.006v.568H6.587Z"/>
                                <path fill-rule="evenodd" d="M11.045 7.73v.544c0 1.131-.636 1.805-1.661 1.805-1.026 0-1.664-.674-1.664-1.805V7.73c0-1.136.638-1.807 1.664-1.807s1.66.674 1.66 1.807Zm-.674.547v-.553c0-.827-.422-1.234-.987-1.234-.572 0-.99.407-.99 1.234v.553c0 .83.418 1.237.99 1.237.565 0 .987-.408.987-1.237m1.15-2.276h1.535c.82 0 1.316.55 1.316 1.292 0 .747-.501 1.289-1.321 1.289h-.865V10h-.665zm1.436 2.036c.463 0 .735-.272.735-.744s-.272-.741-.735-.741h-.774v1.485z"/>
                                <path fill-rule="evenodd" d="M4.893 0a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146A.5.5 0 0 0 11.107 0zM1 5.1 5.1 1h5.8L15 5.1v5.8L10.9 15H5.1L1 10.9z"/>
                                </svg>
                        </div>
                        <h2 class="mb-4 text-xl font-bold leading-tight md:text-2xl">Restricted Items</h2>
                        <p class="font-medium text-gray-600 ">Some items might be in the gray zone, subject to restrictions depending on the destination country. It's on you, the shipper, to be in the loop and comply with these restrictions. Think, pharmaceuticals, and other goodies.</p>
                    </div>
                </div>
            </div>
               
        </div>
    </section>
    <section class="bg-white pb-24 sm:px-20 px-3">
        <div class="mx-auto container md:text-center">
            <div class="max-w-2xl space-y-3 md:mx-auto">
                <h3 class="text-[#EDAC24] font-semibold">
                    Deliver faster
                </h3>
                <p class="text-gray-800 text-3xl font-semibold sm:text-4xl">
                    Our Commitment to Compliance
                </p>
                <p class="text-gray-600">
                    We at Self Ship Label are dedicated to following global shipping laws and guidelines. In addition to ensuring the security of our operations, our proactive approach to identifying and forbidding the shipment of forbidden materials also builds confidence with regulatory bodies and our customers.
                </p>
               
            </div>
           
        </div>
    </section>
    <section class="relative py-24 sm:py-32 sm:px-20 px-3 bg-[#F8EACA]">
        <div class="relative z-10 max-w-screen-xl mx-auto justify-between gap-24 lg:flex">
        <div class="">
            <h3 class=" text-3xl font-semibold sm:text-4xl">
                How to Make Sure Your Shipping Experience Is Easy
            </h3>
            <p class="mt-3 text-gray-600">
                Get Fast, Affordable, Reliable Shipping with Self Ship Label. Sending packages worldwide has never been easier! With Self Ship Label, you're in control.
            </p>
                
        </div>
        <div class="mt-12 max-w-xl lg:mt-0">
            <ul class="grid gap-8 sm:grid-cols-1">
                <li class="flex gap-x-4">
                    <div class="flex-none w-12 h-12 bg-[#EDAC24] text-white rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                        </svg>
                    </div>
                    <div>
                        <h4 class="text-lg font-semibold">
                            Read our list of prohibited items:
                        </h4>
                        <p class="mt-3 text-gray-600">
                            Check that your cargo complies with our requirements by looking over our extensive list of forbidden items before starting a shipment.
                        </p>
                    
                    </div>
                </li>
                <li class="flex gap-x-4">
                    <div class="flex-none w-12 h-12 bg-[#EDAC24] text-white rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                        </svg>
                    </div>
                    <div>
                        <h4 class="text-lg font-semibold">
                            Packaging Recommendations: 
                        </h4>
                        <p class="mt-3 text-gray-600">
                            In order to preserve your goods throughout transportation and to adhere to safety requirements, please follow our suggested packaging recommendations.
                        </p>
                    
                    </div>
                </li>
                <li class="flex gap-x-4">
                    <div class="flex-none w-12 h-12 bg-[#EDAC24] text-white rounded-lg flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-truck" viewBox="0 0 16 16">
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>
                        </svg>
                    </div>
                    <div>
                        <h4 class="text-lg font-semibold">
                            Customer Service: 
                        </h4>
                        <p class="mt-3 text-gray-600">
                            Our customer service staff is here to answer any questions you may have and to guide you through the shipping restrictions and process.
                        </p>
                    
                    </div>
                </li>
            </ul>
        </div>
        </div>
        <div class="absolute top-1/3 inset-0 max-w-md mx-auto h-72 blur-[118px]" :style="{ background: 'linear-gradient(152.92deg, rgba(237, 172, 36, 0.3) 4.54%, rgba(237, 172, 36, 0.26) 34.2%, rgba(237, 172, 36, 0.3) 77.55%)' }"></div>
    </section>
    <section class="bg-white py-24 sm:py-32 sm:px-20 px-3">
        <div class="mx-auto container md:text-center">
            <div class="max-w-2xl space-y-3 md:mx-auto">
                <h3 class="text-[#EDAC24] font-semibold">
                    Professional services
                </h3>
                <p class="text-gray-800 text-3xl font-semibold sm:text-4xl">
                    Choose Self Ship Label – Your Safe, Reliable Shipping Haven
                </p>
                <p class="text-gray-600">
                    When you choose Self Ship Label, you're choosing more than a shipping service. You're opting for a partner dedicated to secure, reliable, and stress-free shipping.
                </p>
                <p class="mt-2 italic text-gray-600 sm:flex items-center justify-center gap-2">
                    <span class="text-[#EDAC24] text-4xl">"</span>Together, let's make your shipping experience a seamless journey!<span class="text-[#EDAC24] text-4xl">"</span>
                </p>
            </div>
           
        </div>
    </section>
</template>