<template>
    <LoaderComponent v-if="loading"></LoaderComponent>

    <section class="container mx-auto py-5 md:px-10 px-3 space-y-5">
        <StepComponent></StepComponent>
        <DangerAlert :message="errors.message"></DangerAlert>

        <template v-if="this.selected_rate">
            <div class="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                role="alert">
                <i class="fas fa-ship mr-1"></i>
                <span class="sr-only">Info</span>
                <div>
                    <span class="font-medium">The selected shipping service is "<b>{{ selected_rate.name }}</b>" with a
                        rate of <b>"${{ selected_rate.total }}"</b> and ship to country <b>"{{ selected_ship_to_country
                            }}".</b>
                    </span>
                </div>
            </div>
        </template>


        <WarningAlert v-if="!pkg.ship_from || !pkg.ship_to"
            :message="'The package ship from address and ship to address is required.'">
        </WarningAlert>


        <div class="grid md:grid-cols-2 gap-5">
            <div class="py-10 bg-[#F5F3F6] rounded">

                <div class="px-5 ">
                    <h3 class="mb-4 text-2xl font-semibold leading-none text-gray-900"> Ship From Address</h3>
                    <form action="#" class="space-y-5">
                        <div>
                            <label for="default-search"
                                class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                            <div class="relative mb-2">
                                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input type="search" id="default-search" v-model="fetch_address.search_ship_from"
                                    @keyup="fetchAddresses(1)"
                                    class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                    placeholder="Search Address ... " required>
                                <button type="button"
                                    class="text-white absolute right-2.5 bottom-2.5 bg-[#EDAC24] hover:bg-[#765100] focus:outline-none font-medium rounded-lg text-sm px-4 py-2">Search</button>
                            </div>
                        </div>

                        <template v-if="fetch_address.type == 1">
                            <div class="row">
                                Note: Click the checkbox to select an address from the list.
                            </div>

                            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <tbody>
                                    <template v-for="address in addresses" :key="address.id">
                                        <tr
                                            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer">
                                            <th scope="row"
                                                class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                                                <input type="checkbox" @click="selectAddress(address.id, address.type)">
                                                <span class="px-2">{{ address.fullname }}</span>
                                            </th>
                                            <td class="px-6 py-4">
                                                {{ address.address }}
                                            </td>
                                            <td class="px-6 py-4">
                                                {{ address.city }}, {{ address.state }} <span
                                                    v-if="address.state">,</span>
                                                {{ address.country_code }}
                                            </td>
                                            <td class="px-6 py-4">
                                                <button type="button"
                                                    class="font-medium text-blue-600 dark:text-blue-500 hover:underline px-2"
                                                    @click="editAddressModal(address.id, 'ship_from')">Edit</button>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </template>

                        <button type="button" class="text-[#EDAC24] font-semibold py-2 px-2"
                            @click="openAddressModal('ship_from')"><i class="fa-solid fa-plus"></i> Add New
                            Address</button>

                        <template v-if="pkg.ship_from">
                            <div class="grid 2xl:grid-cols-3 justify-center items-start gap-5 py-5">
                                <div class="bg-white shadow-md rounded-xl p-5 h-full">
                                    <b>Ship from:</b>
                                    <p class="pt-2">{{ pkg.ship_from.fullname }}</p>
                                    <p>{{ pkg.ship_from.company_name }}</p>
                                    <p>{{ pkg.ship_from.address }}</p>
                                    <p>{{ pkg.ship_from.address_2 }}</p>
                                    <p>{{ pkg.ship_from.address_3 }}</p>
                                    <p>{{ pkg.ship_from.zip_code }}</p>
                                    <p>{{ pkg.ship_from.city }}</p>
                                    <p>{{ pkg.ship_from.state }}</p>
                                    <p>{{ pkg?.ship_from?.country?.name }}</p>
                                </div>
                                <div>
                                    This is your selected ship from address used for the process of generating a Self
                                    Ship
                                    Label.
                                </div>
                            </div>
                        </template>

                    </form>
                </div>
            </div>

            <div class="py-10 bg-[#F5F3F6] rounded">

                <div class="px-5">
                    <h3 class="mb-4 text-2xl font-semibold leading-none text-gray-900"> Ship To Address</h3>

                    <form action="#" class=" space-y-5">
                        <div>
                            <label for="default-search"
                                class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                            <div class="relative mb-2">
                                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input type="search" id="default-search" v-model="fetch_address.search_ship_to"
                                    @keyup="fetchAddresses(2)"
                                    class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                    placeholder="Search Address ..." required>
                                <button type="button"
                                    class="text-white absolute right-2.5 bottom-2.5 bg-[#EDAC24] hover:bg-[#765100] focus:outline-none font-medium rounded-lg text-sm px-4 py-2">Search</button>
                            </div>
                        </div>

                        <template v-if="fetch_address.type == 2">
                            <div class="row">
                                Note: Click the checkbox to select an address from the list.
                            </div>

                            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <tbody>
                                    <template v-for="address in addresses" :key="address.id">
                                        <tr
                                            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600">
                                            <th scope="row" @click="selectAddress(address.id, address.type)"
                                                class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white cursor-pointer">
                                                <input type="checkbox" class="cursor-pointer">
                                                <span class="px-2">{{ address.fullname }}</span>
                                            </th>
                                            <td class="px-6 py-4">
                                                {{ address.address }}
                                            </td>
                                            <td class="px-6 py-4">
                                                {{ address.city }}, {{ address.state }} <span
                                                    v-if="address.state">,</span>
                                                {{ address.country_code }}
                                            </td>
                                            <td class="px-6 py-4">
                                                <button type="button"
                                                    class="font-medium text-blue-600 dark:text-blue-500 hover:underline px-2"
                                                    @click="editAddressModal(address.id, 'ship_to')">Edit</button>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </template>

                        <button type="button" class="text-[#EDAC24] font-semibold py-2 px-2"
                            @click="openAddressModal('ship_to')">
                            <i class="fa-solid fa-plus"></i> Add New Address</button>

                        <template v-if="pkg.ship_to">
                            <div class="grid 2xl:grid-cols-3 justify-center items-start gap-5 py-5">
                                <div class="bg-white shadow-md rounded-xl p-5 h-full">
                                    <b>Ship to:</b>
                                    <p class="pt-2">{{ pkg?.ship_to?.fullname }}</p>
                                    <p>{{ pkg?.ship_to?.company_name }}</p>
                                    <p>{{ pkg?.ship_to?.address }}</p>
                                    <p>{{ pkg?.ship_to?.address_2 }}</p>
                                    <p>{{ pkg?.ship_to?.address_3 }}</p>
                                    <p>{{ pkg?.ship_to?.zip_code }}</p>
                                    <p>{{ pkg?.ship_to?.city }}</p>
                                    <p>{{ pkg?.ship_to?.state }}</p>
                                    <p>{{ pkg?.ship_to?.country?.name }}</p>
                                </div>
                                <div>
                                    This is your selected ship to address used for the process of generating a Self Ship
                                    Label.
                                </div>
                            </div>
                        </template>

                    </form>
                </div>
            </div>
        </div>

        <template v-if="address_modal">
            <div id="defaultModal" tabindex="-1" aria-hidden="true"
                class="z-[1000] fixed bg-[rgba(11,11,11,0.5)] m-[0!important] top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-[100vh] h-[100vh] ">
                <div class="relative m-auto w-full max-w-2xl h-full max-h-[100vh]">
                    <!-- Modal content -->
                    <div
                        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white w-full rounded-lg shadow">
                        <!-- Modal header -->
                        <div class="flex items-start justify-between p-4 border-b rounded-t">
                            <h3 class="mb-2 text-2xl leading-none font-bold text-gray-900">
                                {{ form.type == 'ship_from' ? 'Ship From' : 'Ship To' }} Address
                            </h3>
                            <button type="button" @click="closeAddressModal"
                                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-hide="defaultModal">
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>

                        <!-- Body -->
                        <div class="py-3 px-5 overflow-y-auto">
                            <DangerAlert :message="errors.message"></DangerAlert>

                            <form @submit.prevent="handleSubmit" class="space-y-3">
                                <div
                                    class="grid gap-4 mb-4 sm:grid-cols-2 h-[450px] md:h-[580px] overflow-y-auto pr-2 pl-2 max-h-[20rem] 2xl:max-h-[20rem] 3xl:max-h-[20rem]">
                                    <div>
                                        <label for="address1"
                                            class="block mb-2 text-sm font-medium text-gray-900">Company
                                            Name</label>
                                        <input type="text" v-model="form.company_name"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500"
                                            placeholder="">
                                        <span class="text-red-600">{{ errors?.errors?.company_name }}</span>
                                    </div>
                                    <div>
                                        <label for="address1" class="block mb-2 text-sm font-medium text-gray-900">Full
                                            Name *</label>
                                        <input type="text" v-model="form.fullname"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500"
                                            placeholder="">
                                        <span class="text-red-600">{{ errors?.errors?.fullname }}</span>
                                    </div>
                                    <div>
                                        <label for="address1" class="block mb-2 text-sm font-medium text-gray-900">Is
                                            Residential?</label>
                                        <select v-model="form.is_residential"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500">
                                            <option :value="true">Yes</option>
                                            <option :value="false">No</option>
                                        </select>
                                        <span class="text-red-600">{{ errors?.errors?.is_residential }}</span>
                                    </div>
                                    <div>
                                        <label for="address1"
                                            class="block mb-2 text-sm font-medium text-gray-900">Address
                                            line 1 *</label>
                                        <input type="text" v-model="form.address" id="address1"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500"
                                            placeholder="">
                                        <span class="text-red-600">{{ errors?.errors?.address }}</span>
                                    </div>
                                    <div>
                                        <label for="address2"
                                            class="block mb-2 text-sm font-medium text-gray-900">Address
                                            line 2</label>
                                        <input type="text" v-model="form.address_2" id="address2"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                            placeholder="">
                                        <span class="text-red-600">{{ errors?.errors?.address_2 }}</span>
                                    </div>
                                    <div>
                                        <label for="address2"
                                            class="block mb-2 text-sm font-medium text-gray-900">Address
                                            line 3</label>
                                        <input type="text" v-model="form.address_3" id="address2"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                            placeholder="">
                                        <span class="text-red-600">{{ errors?.errors?.address_3 }}</span>
                                    </div>
                                    <div>
                                        <label for="country"
                                            class="block mb-2 text-sm font-medium text-gray-900">Country
                                            *</label>
                                        <select v-model="form.country_id"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5">
                                            <template v-if="form.type == 'ship_to'">
                                                <option value="">Select</option>
                                            </template>
                                            <template v-for="country in countries" :key="country.id">
                                                <option :value="country.id">{{ country.name }}</option>
                                            </template>
                                        </select>
                                        <span class="text-red-600">{{ errors?.errors?.country_id }}</span>
                                    </div>
                                    <div>
                                        <label for="country" class="block mb-2 text-sm font-medium text-gray-900">
                                            <span>State</span>
                                            <span v-if="form.country_id == 226">*</span>
                                        </label>

                                        <template v-if="form.country_id == 226">
                                            <select v-model="form.state"
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5">
                                                <template v-for="state in states" :key="state.id">
                                                    <option :value="state.abbr">{{ state.name }}</option>
                                                </template>
                                            </select>
                                        </template>

                                        <template v-else>
                                            <input type="text" v-model="form.state"
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                                placeholder="">
                                        </template>

                                        <span class="text-red-600">{{ errors?.errors?.state }}</span>
                                    </div>
                                    <div>
                                        <label for="city" class="block mb-2 text-sm font-medium text-gray-900">City
                                            *</label>
                                        <input type="text" v-model="form.city"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                            placeholder="">
                                        <span class="text-red-600">{{ errors?.errors?.city }}</span>
                                    </div>
                                    <div>
                                        <label for="postal-code" class="block mb-2 text-sm font-medium text-gray-900">
                                            Zip
                                            code *</label>
                                        <input type="text" v-model="form.zip_code"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                            placeholder="">
                                        <span class="text-red-600">{{ errors?.errors?.zip_code }}</span>
                                    </div>
                                    <div>
                                        <label for="postal-code"
                                            class="block mb-2 text-sm font-medium text-gray-900">Tax
                                            ID</label>
                                        <input type="text" v-model="form.tax_no"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                            placeholder="">
                                        <span class="text-red-600">{{ errors?.errors?.tax_no }}</span>
                                    </div>
                                    <div>
                                        <label for="postal-code"
                                            class="block mb-2 text-sm font-medium text-gray-900">Phone
                                            *</label>
                                        <input type="text" v-model="form.phone"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                            placeholder="">
                                        <span class="text-red-600">{{ errors?.errors?.phone }}</span>
                                    </div>
                                    <div>
                                        <label for="postal-code"
                                            class="block mb-2 text-sm font-medium text-gray-900">Email
                                            *</label>
                                        <input type="email" v-model="form.email"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                            placeholder="">
                                        <span class="text-red-600">{{ errors?.errors?.email }}</span>
                                    </div>
                                    <div>
                                        <label for="country"
                                            class="block mb-2 text-sm font-medium text-gray-900">Signature Type
                                            *</label>
                                        <select v-model="form.signature_type_id"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5">
                                            <template v-for="signature_type in signature_types"
                                                :key="signature_type.id">
                                                <option :value="signature_type.id">{{ signature_type.name }}</option>
                                            </template>
                                        </select>
                                        <span class="text-red-600">{{ errors?.errors?.signature_type_id }}</span>
                                    </div>
                                </div>

                                <button type="submit"
                                    class="text-white bg-[#EDAC24] hover:bg-[#f7a900] focus:ring-4 focus:outline-nonefont-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                    {{ loading ? "Loading ..." : "Submit" }}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <div class="flex justify-between items-center px-10 py-5">
            <BackButton></BackButton>

            <template v-if="pkg.ship_from && pkg.ship_to">
                <router-link :to="pkg.pkg_ship_type == 'domestic' ? { name: 'booking' } : { name: 'customs' }">
                    <button type="button"
                        class="text-white bg-[#ffcb5a] hover:bg-[#f7a900] focus:ring-4 focus:outline-nonefont-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        Next
                    </button>
                </router-link>
            </template>

            <template v-else>
                <button type="button"
                    class="text-white bg-[#ffcb5a] hover:bg-[#f7a900] focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center opacity-50 cursor-not-allowed"
                    disabled>
                    Next
                </button>
            </template>

        </div>
    </section>
</template>

<script>
import BackButton from './Global/BackButton.vue';
import StepComponent from './StepComponent.vue';
import DangerAlert from './Global/Alerts/DangerAlert.vue';
import WarningAlert from './Global/Alerts/WarningAlert.vue';
import LoaderComponent from './Partials/LoaderComponent.vue';

export default {
    name: "AddressComponent",
    data() {
        return {
            loading: false,
            selected_rate: {
                name: "",
                total: "",
            },
            selected_ship_to_country: "",
            address_modal: false,
            countries: [],
            states: [],
            signature_types: [],
            addresses: [],
            pkg: {},
            form: {
                company_name: "",
                fullname: "",
                is_residential: "",
                country_id: "",
                state: "",
                city: "",
                zip_code: "",
                phone: "",
                email: "",
                address: "",
                address_2: "",
                address_3: "",
                tax_no: "",
                type: "",
                signature_type_id: "",
                update_flag: false,
                address_id: null,
            },
            fetch_address: {
                type: "",
                search_ship_from: "",
                search_ship_to: "",
            },
            set_address: {
                type: "",
                id: "",
                selected_country_code: "",
            },
            errors: {}
        };
    },
    props: {
        active: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        openAddressModal(type) {
            this.form = {};
            this.errors = {};
            this.address_modal = true;
            this.form.type = type;
            this.form.is_residential = true;
            this.form.signature_type_id = 1;

            if (this.form.type == 'ship_from') {
                this.form.country_id = 226;
            } else {
                this.form.country_id = "";
            }

            this.fetchRecords();
        },
        editAddressModal(address_id, address_type) {
            this.form = {};
            this.errors = {};

            this.form.type = address_type;
            this.form.address_id = address_id;
            this.form.update_flag = true;
            this.address_modal = true;
            this.fetchRecords();

            const url = "get-address"
            this.$axios
                .post(url, {
                    address_id: address_id
                }, {
                    headers: this.$headers,
                })
                .then((response) => {
                    var address = response.data.data.address;
                    this.form.company_name = address.company_name;
                    this.form.fullname = address.fullname;
                    this.form.is_residential = address.is_residential == 1 ? true : false;
                    this.form.country_id = address.country_id;
                    this.form.state = address.state;
                    this.form.city = address.city;
                    this.form.zip_code = address.zip_code;
                    this.form.phone = address.phone;
                    this.form.email = address.email;
                    this.form.address = address.address;
                    this.form.address_2 = address.address_2;
                    this.form.address_3 = address.address_3;
                    this.form.tax_no = address.tax_no;
                    this.form.signature_type_id = address.signature_type_id;
                })
                .catch((error) => {
                    console.log(error);
                    window.scrollTo(0, 0);
                });
        },
        closeAddressModal() {
            this.address_modal = false;
            this.form = {};
            this.errors = {};
        },
        fetchRecords() {
            const url = "data"
            this.$axios
                .post(url, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.countries = [];
                    for (const country of response.data.data.countries) {
                        if (this.form.type === "ship_from") {
                            if (country.iso === 'US') {
                                this.countries.push(country);
                            }
                        } else {
                            this.countries.push(country);
                        }
                    }
                    this.states = response.data.data.states;
                    this.signature_types = response.data.data.signature_types;
                })
                .catch((error) => {
                    console.log(error);
                    window.scrollTo(0, 0);
                });
        },
        handleSubmit() {
            this.loading = true;
            this.errors = {};

            this.$axios
                .post("address/store", this.form, {
                    headers: this.$headers
                })
                .then((response) => {
                    if (response.data.status == true) {
                        this.closeAddressModal();
                        this.selectAddress(response.data.data.address_id, response.data.data.address_type);
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.errors = error.response.data;
                    console.log(error.response.data);
                    this.loading = false;
                    window.scrollTo(0, 0);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchPackage() {
            this.loading = true;
            const url = "package/get-package"
            this.$axios
                .post(url, null, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.pkg = response.data.data.package;

                    if (this?.pkg?.ship_to?.country_code) {
                        if (this.selected_ship_to_country != this.pkg.ship_to.country_code) {
                            this.selectAddress(null, 'ship_to');
                        }
                    }

                    // if (this.selected_rate.total != this.pkg.grand_total) {
                    this.selected_rate.total = this.pkg.grand_total;
                    var rate = {
                        name: this.selected_rate.name,
                        total: this.selected_rate.total,
                    }
                    localStorage.setItem('selected_rate', JSON.stringify(rate));
                    // }

                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    window.scrollTo(0, 0);
                });
        },
        fetchAddresses(type) {
            this.fetch_address.type = type;

            const url = "addresses"
            this.$axios
                .post(url, this.fetch_address, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.addresses = response.data.data.addresses;
                })
                .catch((error) => {
                    console.log(error);
                    window.scrollTo(0, 0);
                });
        },
        selectAddress(id, type) {

            this.loading = true;
            this.errors = {};
            this.set_address.id = id;
            this.set_address.type = type;
            this.set_address.selected_country_code = this.selected_ship_to_country;

            const url = "package/set-address"
            this.$axios
                .post(url, this.set_address, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.addresses = response.data.data.addresses;
                    this.fetchPackage();
                    this.loading = false;
                })
                .catch((error) => {
                    this.errors = error.response.data;
                    window.scrollTo(0, 0);
                    this.fetchPackage();
                    this.loading = false;
                });
        },
    },
    components: { BackButton, StepComponent, DangerAlert, LoaderComponent, WarningAlert },
    mounted() {
        this.fetchPackage();
        this.selected_rate = JSON.parse(localStorage.getItem('selected_rate'));
        this.selected_ship_to_country = localStorage.getItem('ship_to_country_code');
    }
};
</script>