<template>
    <section class="container mx-auto py-5 sm:px-10 px-3 space-y-5">


        <div class="py-10 bg-[#F5F3F6] rounded">

            <div class="sm:px-5 px-2">

                <div class="space-y-5">
                    <h3 class="mb-4 text-2xl font-semibold leading-none text-gray-900"> Important - Please Confirm the
                        following</h3>
                    <ul class="space-y-5 sm:pl-5 py-5">
                        <li class="grid grid-cols-[20px,1fr] items-center gap-3"> <input type="checkbox"
                                class="h-[20px] w-[20px]" /> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Saepe
                            eveniet earum cumque officiis maxime veritatis dolorem porro omnis vero tenetur!</li>
                        <li class="grid grid-cols-[20px,1fr] items-center gap-3"> <input type="checkbox"
                                class="h-[20px] w-[20px]" /> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Saepe
                            eveniet earum cumque officiis maxime veritatis dolorem porro omnis vero tenetur!</li>
                        <li class="grid grid-cols-[20px,1fr] items-center gap-3"> <input type="checkbox"
                                class="h-[20px] w-[20px]" /> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Saepe
                            eveniet earum cumque officiis maxime veritatis dolorem porro omnis vero tenetur!</li>
                    </ul>
                    <div class="sm:p-5 p-2 bg-[#ffffd9] grid grid-cols-[20px,1fr] items-center gap-5">
                        <input type="checkbox" class="h-[20px] w-[20px]" />
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                            Sit numquam iste similique eaque doloribus ipsam, at, neque
                            dolore, nihil maxime officiis expedita animi nam. Suscipit
                            ipsum molestias ipsam, repudiandae necessitatibus eveniet
                            natus magni repellat voluptatibus ea nisi, tempore excepturi!
                            Temporibus reprehenderit deserunt, quod id ea dolor molestiae
                            illum odit quos repellendus tempore amet consequatur nostrum
                            ipsa adipisci magnam
                        </p>
                    </div>

                </div>

                <div class="py-5">
                    <hr class="bg-gray-300 h-[1.5px] w-full">
                </div>
            </div>

        </div>

    </section>

    <section class="container mx-auto sm:space-y-0 space-y-5 py-5 sm:px-10 px-3 grid sm:grid-cols-6 items-center justify-center sm:h-[50vh]">
        <div class="sm:col-span-4 h-full sm:mb-0 mb-3">
            <div class="flex justify-items-center items-end lg:gap-10 gap-5 sm:flex-row flex-col">
                <div class="w-full">
                    <label for="payment-type" class=" block mb-4 text-lg font-medium text-gray-900">Payment type</label>
                    <select type="text" name="payment-type" id="payment-type"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                        required>
                        <option value="">JazzCash</option>
                        <option value="">EasyPaisa</option>
                        <option value="">Paypal</option>
                    </select>
                </div>
                <img class="h-[50px] w-[400px]" src="../assets/images/payment-methods.png" alt="payment-methods">
            </div>
        </div>
        <div class="bg-[#F5F3F6] rounded sm:col-span-2 h-full p-5 space-y-5">
            <div class="flex justify-between items-center font-semibold">
                <p>Your order</p>
                <p>USD $</p>
            </div>
            <hr class="block h-[2px] w-full" />
            <div class="space-y-3">
                <div class="flex justify-between items-center font-medium">
                    <p>Sub total</p>
                    <p>$441.58</p>
                </div>
                <div class="flex justify-between items-center font-medium">
                    <p>VAT</p>
                    <p>$0.00</p>
                </div>
                <div class="flex justify-between items-center font-semibold">
                    <p>TOTAL</p>
                    <p>$441.58</p>
                </div>
            </div>
            <hr class="block h-[2px] w-full" />
            <button class="bg-[#EDAC24] w-full py-2 rounded font-semibold text-white">Pay Bill</button>
        </div>
        <div class="px-5">
            <BackButton></BackButton>
        </div>
    </section>
</template>

<script>
import BackButton from './Global/BackButton.vue';
export default {
    name: "PaymentComponent",
    data() {
        return {
            //
        };
    },
    props: {
        active: {
            type: Boolean,
            required: true,
        },
    },
    components: { BackButton }
};
</script>



