<template>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg p-4">
        <!-- <div class="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
            <label for="table-search" class="sr-only">Search</label>
            <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                </div>
                <input type="text" id="table-search-users"
                    class="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search for users">
            </div>
        </div> -->
        <table class="min-w-[1200px] w-full text-sm text-left text-gray-500 dark:text-gray-400 rounded-lg">
            <thead class="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400 bg-[#F8EACA]">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        SR.NO.
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Name
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Address
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Type
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(address, index) in addresses" :key="address.id">
                    <tr
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td class="px-6 py-4">
                            {{ index + 1 }}
                        </td>
                        <th scope="row"
                            class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                            <div class="pl-3">
                                <div class="text-base font-semibold">{{ address.fullname }}</div>
                                <div class="font-normal text-gray-500">{{ address.email }}</div>
                            </div>
                        </th>
                        <td class="px-6 py-4">
                            {{ address.address }} <br>
                            {{ address.address_2 }} <br>
                            {{ address.address_3 }} <br>
                        </td>
                        <td class="px-6 py-4">
                           <span class="uppercase"> {{ address.type }}</span>
                        </td>
                        <td class="px-6 py-4">
                            <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                @click="editAddressModal(address.id, address.type)">Edit</a>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>

    <template v-if="address_modal">
        <div id="defaultModal" tabindex="-1" aria-hidden="true"
            class="z-[1000] fixed bg-[rgba(11,11,11,0.5)] m-[0!important] top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-[100vh] h-[100vh] ">
            <div class="relative m-auto w-full max-w-2xl h-full max-h-[100vh]">
                <!-- Modal content -->
                <div
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white w-full rounded-lg shadow">
                    <!-- Modal header -->
                    <div class="flex items-start justify-between px-4 pt-4 pb-2 border-b rounded-t">
                        <h3 class="mb-2 text-2xl leading-none font-bold text-gray-900">
                            {{ form.type == 'ship_from' ? 'Ship From' : 'Ship To' }} Address
                        </h3>
                        <button type="button" @click="closeAddressModal"
                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="defaultModal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>

                    <!-- Body -->
                    <div class="py-3 px-5 overflow-y-auto">
                        <DangerAlert :message="errors.message"></DangerAlert>

                        <form @submit.prevent="handleSubmit" class="space-y-3">
                            <div
                                class="grid gap-4 mb-4 sm:grid-cols-2 h-[450px] md:h-[580px] overflow-y-auto pr-2 pl-2 max-h-[20rem] 2xl:max-h-[20rem] 3xl:max-h-[20rem]">
                                <div>
                                    <label for="address1" class="block mb-2 text-sm font-medium text-gray-900">Company
                                        Name</label>
                                    <input type="text" v-model="form.company_name"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.company_name }}</span>
                                </div>
                                <div>
                                    <label for="address1" class="block mb-2 text-sm font-medium text-gray-900">Full
                                        Name *</label>
                                    <input type="text" v-model="form.fullname"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.fullname }}</span>
                                </div>
                                <div>
                                    <label for="address1" class="block mb-2 text-sm font-medium text-gray-900">Is
                                        Residential?</label>
                                    <select v-model="form.is_residential"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <span class="text-red-600">{{ errors?.errors?.is_residential }}</span>
                                </div>
                                <div>
                                    <label for="address1" class="block mb-2 text-sm font-medium text-gray-900">Address
                                        line 1 *</label>
                                    <input type="text" v-model="form.address" id="address1"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 blue-500"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.address }}</span>
                                </div>
                                <div>
                                    <label for="address2" class="block mb-2 text-sm font-medium text-gray-900">Address
                                        line 2</label>
                                    <input type="text" v-model="form.address_2" id="address2"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.address_2 }}</span>
                                </div>
                                <div>
                                    <label for="address2" class="block mb-2 text-sm font-medium text-gray-900">Address
                                        line 3</label>
                                    <input type="text" v-model="form.address_3" id="address2"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.address_3 }}</span>
                                </div>
                                <div>
                                    <label for="country" class="block mb-2 text-sm font-medium text-gray-900">Country
                                        *</label>
                                    <select v-model="form.country_id"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5">
                                        <template v-if="form.type == 'ship_to'">
                                            <option value="">Select</option>
                                        </template>
                                        <template v-for="country in countries" :key="country.id">
                                            <option :value="country.id">{{ country.name }}</option>
                                        </template>
                                    </select>
                                    <span class="text-red-600">{{ errors?.errors?.country_id }}</span>
                                </div>
                                <div>
                                    <label for="country" class="block mb-2 text-sm font-medium text-gray-900">
                                        <span>State</span>
                                        <span v-if="form.country_id == 226">*</span>
                                    </label>

                                    <template v-if="form.country_id == 226">
                                        <select v-model="form.state"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5">
                                            <template v-for="state in states" :key="state.id">
                                                <option :value="state.abbr">{{ state.name }}</option>
                                            </template>
                                        </select>
                                    </template>

                                    <template v-else>
                                        <input type="text" v-model="form.state"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                            placeholder="">
                                    </template>

                                    <span class="text-red-600">{{ errors?.errors?.state }}</span>
                                </div>
                                <div>
                                    <label for="city" class="block mb-2 text-sm font-medium text-gray-900">City
                                        *</label>
                                    <input type="text" v-model="form.city"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.city }}</span>
                                </div>
                                <div>
                                    <label for="postal-code" class="block mb-2 text-sm font-medium text-gray-900">
                                        Zip
                                        code *</label>
                                    <input type="text" v-model="form.zip_code"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.zip_code }}</span>
                                </div>
                                <div>
                                    <label for="postal-code" class="block mb-2 text-sm font-medium text-gray-900">Tax
                                        ID</label>
                                    <input type="text" v-model="form.tax_no"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.tax_no }}</span>
                                </div>
                                <div>
                                    <label for="postal-code" class="block mb-2 text-sm font-medium text-gray-900">Phone
                                        *</label>
                                    <input type="text" v-model="form.phone"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.phone }}</span>
                                </div>
                                <div>
                                    <label for="postal-code" class="block mb-2 text-sm font-medium text-gray-900">Email
                                        *</label>
                                    <input type="email" v-model="form.email"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
                                        placeholder="">
                                    <span class="text-red-600">{{ errors?.errors?.email }}</span>
                                </div>
                                <div>
                                    <label for="country" class="block mb-2 text-sm font-medium text-gray-900">Signature
                                        Type
                                        *</label>
                                    <select v-model="form.signature_type_id"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5">
                                        <template v-for="signature_type in signature_types" :key="signature_type.id">
                                            <option :value="signature_type.id">{{ signature_type.name }}</option>
                                        </template>
                                    </select>
                                    <span class="text-red-600">{{ errors?.errors?.signature_type_id }}</span>
                                </div>
                            </div>

                            <button type="submit"
                                class="text-white bg-[#EDAC24] hover:bg-[#f7a900] focus:ring-4 focus:outline-nonefont-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                {{ loading ? "Loading ..." : "Update" }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<script>

export default {
    name: "AccountAddress",
    data() {
        return {
            addresses: [],
            loading: false,
            project_url: process.env.VUE_APP_PROJECT_URL,
            address_modal: false,
            form: {
                company_name: "",
                fullname: "",
                is_residential: "",
                country_id: "",
                state: "",
                city: "",
                zip_code: "",
                phone: "",
                email: "",
                address: "",
                address_2: "",
                address_3: "",
                tax_no: "",
                type: "",
                signature_type_id: "",
                update_flag: false,
                address_id: null,
            },
            errors: {}
        };
    },

    methods: {
        fetchAddresses() {
            this.loading = true;
            const url = "addresses";
            this.$axios
                .post(url, null, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.addresses = response.data.data.addresses;
                    console.log(response);
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        editAddressModal(address_id, address_type) {
            console.log(address_id, address_type);
            this.form = {};
            this.errors = {};

            this.form.type = address_type;
            this.form.address_id = address_id;
            this.form.update_flag = true;
            this.address_modal = true;
            this.fetchRecords();

            const url = "get-address"
            this.$axios
                .post(url, {
                    address_id: address_id
                }, {
                    headers: this.$headers,
                })
                .then((response) => {
                    console.log(response);
                    var address = response.data.data.address;
                    this.form.company_name = address.company_name;
                    this.form.fullname = address.fullname;
                    this.form.is_residential = address.is_residential == 1 ? true : false;
                    this.form.country_id = address.country_id;
                    this.form.state = address.state;
                    this.form.city = address.city;
                    this.form.zip_code = address.zip_code;
                    this.form.phone = address.phone;
                    this.form.email = address.email;
                    this.form.address = address.address;
                    this.form.address_2 = address.address_2;
                    this.form.address_3 = address.address_3;
                    this.form.tax_no = address.tax_no;
                    this.form.signature_type_id = address.signature_type_id;
                })
                .catch((error) => {
                    console.log(error);
                    // window.scrollTo(0, 0);
                });
        },
        handleSubmit() {
            this.loading = true;
            this.errors = {};

            this.$axios
                .post("address/store", this.form, {
                    headers: this.$headers
                })
                .then((response) => {
                    if (response.data.status == true) {
                        this.closeAddressModal();
                        this.fetchAddresses();
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.errors = error.response.data;
                    console.log(error.response.data);
                    this.loading = false;
                    window.scrollTo(0, 0);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        closeAddressModal() {
            this.address_modal = false;
            this.form = {};
            this.errors = {};
        },
        fetchRecords() {
            const url = "data"
            this.$axios
                .post(url, {
                    headers: this.$headers,
                })
                .then((response) => {
                    this.countries = [];
                    for (const country of response.data.data.countries) {
                        if (this.form.type === "ship_from") {
                            if (country.iso === 'US') {
                                this.countries.push(country);
                            }
                        } else {
                            this.countries.push(country);
                        }
                    }
                    this.states = response.data.data.states;
                    this.signature_types = response.data.data.signature_types;
                })
                .catch((error) => {
                    console.log(error);
                    window.scrollTo(0, 0);
                });
        },
    },
    mounted() {
        this.fetchAddresses();
    }
};
</script>